import { SettingRepository } from '#repositories/setting.repository';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Setting } from '#models/setting.model';
import { LoaderService } from '#services/loader.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingResolver implements Resolve<Setting> {
  constructor(
    private settingRepository: SettingRepository,
    private loaderService: LoaderService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.settingRepository.getSettings().pipe(
      tap((setting) => {
        this.loaderService.setting = setting;
      })
    );
  }
}
