<app-page-header [title]="'screen_name.facility_indication' | trans">
  <button (click)="onCreateOnEdit()" mat-stroked-button color="primary">
    <mat-icon>add</mat-icon> {{ "actions.register" | trans }}
  </button>
</app-page-header>
<app-empty-data
  [dataSource]="dataSource"
  (refresh)="loadDatasource()"
  [template]="template"
  [key]="apiUri"
>
</app-empty-data>
<ng-template #template>
  <section class="cursor-pointer">
    <div>
      <ngx-datatable
        [rows]="dataSource"
        [externalPaging]="true"
        [count]="pagination?.total"
        [offset]="pagination?.page"
        [limit]="pagination?.perPage"
        (page)="onPageChange($event)"
        rowHeight="auto"
        [headerHeight]="50"
        [footerHeight]="50"
        [messages]="datatableMessage"
        columnMode="flex"
        class="material striped"
      >
        <ngx-datatable-column
          [name]="'facility_indication.no' | trans"
          [flexGrow]="1"
          [sortable]="false"
        >
          <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <p>{{ rowIndex + 1 }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'facility_indication.facility_name' | trans"
          [flexGrow]="8"
          prop="name"
          [sortable]="false"
        >
          <ng-template let-value="value" ngx-datatable-cell-template>
            <p>{{ value }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'facility_indication.working_time' | trans"
          [flexGrow]="3"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <p>{{ getWorkingTime(row) }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'facility_indication.status' | trans"
          [flexGrow]="3"
          [sortable]="false"
          prop="status"
        >
          <ng-template let-value="value" ngx-datatable-cell-template>
            <p class="text-lg">{{ getStatus(value) | trans }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'common.actions' | trans"
          [flexGrow]="2"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button
              mat-icon-button
              [matTooltip]="'actions.edit' | trans"
              matTooltipPosition="above"
              color="primary"
              (click)="onCreateOnEdit(row)"
            >
              <mat-icon> edit </mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              [matTooltip]="'actions.delete' | trans"
              matTooltipPosition="above"
              class="ml-2"
              (click)="onDelete(row?.id)"
            >
              <mat-icon> delete </mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </section>
</ng-template>
