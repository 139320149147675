import { ValidatorFn, AbstractControl } from '@angular/forms';

export function customPatternValidator(config: {
  pattern: RegExp | RegExp[];
  invalidMessage: string;
}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!config.pattern) {
      return null;
    }

    const value = control?.value?.toString() || '';
    if (!value) {
      return null;
    }

    let patterns = [];
    if (config.pattern && Array.isArray(config.pattern)) {
      patterns = [...config.pattern];
    } else {
      patterns.push(config.pattern);
    }

    let isValid = false;
    patterns.forEach((pattern) => {
      if (pattern.test(value)) {
        isValid = true;
      }
    });

    if (!isValid) {
      return {
        pattern: {
          invalidMessage: config?.invalidMessage,
        },
      };
    }
    return null;
  };
}
