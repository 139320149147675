<app-dialog
  [dialogTitle]="
    (isUpdate
      ? 'common.edit_form_title'
      : 'common.add_form_title'
    ) | trans
  "
  [backButton]="'actions.cancel' | trans"
  actionAlign="center"
>
  <div dialog-body class="px-3 mb-8">
    <app-category-form
      [mainCategories]="data?.mainCategories"
      [data]="data?.category"
      [isUpdate]="isUpdate"
    >
    </app-category-form>
  </div>
  <div dialog-action>
    <button
      mat-flat-button
      (click)="onSubmit()"
      [disabled]="categoryForm?.isInvalid"
      color="primary"
    >
      {{ (isUpdate ? "actions.update" : "actions.insert") | trans }}
    </button>
  </div>
</app-dialog>
