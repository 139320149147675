import { Model } from './model';
import { existOrEmptyArray } from '#utils/helpers';
import { Mapper } from '#interfaces/mapper.interface';
import {
  SurveyEditTranslation
} from '#models/translation.model';
import { DEFAULT_LANGUAGE } from '#utils/const';

export class SurveyAnswer extends Model implements Mapper {
  createdAt: string;
  updatedAt?: string;
  ratio?: number;
  content: string;
  translations: { [key: string]: { content: string } };
  mapFromAPI(answer: any): SurveyAnswer {
    this.id = answer?.id;
    this.ratio = answer?.ratio;
    this.content = answer?.content;
    this.translations = {
      [DEFAULT_LANGUAGE]: { content: answer?.content },
    };
    existOrEmptyArray(answer?.content_translations).forEach((content) => {
      this.translations[content?.language_code] = { content: content?.content };
    });
    this.createdAt = answer?.created_at;
    this.updatedAt = answer?.updated_at;
    return this;
  }
}

export class SurveyAnswerEdit extends Model implements Mapper {
  'content': string;
  'translations': SurveyEditTranslation[];
  mapToAPI(update: SurveyAnswer): SurveyAnswerEdit {
    this.content = update?.translations[DEFAULT_LANGUAGE]?.content;
    const newTranslations = { ...update?.translations };
    delete newTranslations[DEFAULT_LANGUAGE];
    this.translations = Object.keys(newTranslations).map((key) =>
      new SurveyEditTranslation().mapToAPI({
        languageCode: key,
        content: newTranslations[key]?.content,
      })
    );
    return this;
  }
}
