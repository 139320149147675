<div
  class="dialog-header border-bottom"
  [ngClass]="{ 'no-title': !dialogTitle }"
>
  <h2 mat-dialog-title class="font-bold">{{ dialogTitle }}</h2>
</div>
<div class="dialog-content-scrollable" #dialogContent>
  <div class="dialog-note mb-30">
    <ng-content select="[dialog-note]"></ng-content>
  </div>
  <div mat-dialog-content>
    <ng-content select="[dialog-body]"></ng-content>
  </div>
</div>
<div mat-dialog-actions class="full-width" [align]="actionAlign">
  <ng-content select="[dialog-action]"></ng-content>
  <button
    mat-stroked-button
    (click)="onDismiss()"
    type="button"
    color="primary"
    class="ml-2"
  >
    {{ backButton | trans }}
  </button>
</div>
