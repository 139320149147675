import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpException } from '#interfaces/exception.interface';
import { DialogService } from './dialog.service';
import { TranslateService } from './translate.service';
import { Router } from '@angular/router';
import { isString } from '#utils/helpers';

export class UnauthenticatedException implements HttpException {
  code = '401';
  message = 'Unauthenticated';
}

@Injectable()
export class HttpErrorHandler {
  private apiFetchMap: Map<string, BehaviorSubject<boolean>> = new Map();
  private messages: string[] = [];

  /**
   * HttpErrorHandler Constructor.
   */
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  /**
   * Handle http request error.
   */
  public handle(response: HttpErrorResponse) {
    const body = this.parseJson(response.error);
    let message = null;

    if (response.status === 403) {
      message = this.translateService.translate('message.no_permission');
    } else if (body) {
      message = body.message;
      if (!isString(message)) {
        let messages = '';
        for (const error in message) {
          if (message.hasOwnProperty(error)) {
            messages += message[error][0] + '\n';
          }
        }
        message = messages;
      }

      message =
        message || this.translateService.translate('message.disconnect');
    }

    if (message && !this.messages.includes(message)) {
      this.messages.push(message);

      const dialogRef = this.dialogService.warning(message);
      dialogRef
        .afterClosed()
        .toPromise()
        .then(() => {
          this.messages.splice(this.messages.indexOf('message'), 1);
          if (response.status === 401) {
            this.router.navigate(['/login'], { state: { expired: true } });
          }
        });
    }

    return throwError(response);
  }

  /**
   * Parse JSON without throwing errors.
   */
  private parseJson(json: string): { message?: string } {
    if (typeof json !== 'string') {
      return json;
    }

    try {
      return JSON.parse(json);
    } catch (e) {
      return {};
    }
  }

  getApiFetchMapData(key: string): BehaviorSubject<boolean> {
    this.setDefaultApiFetchMapData(key);
    return this.apiFetchMap.get(key);
  }

  setApiFetchMapData(key: string, value: boolean) {
    this.getApiFetchMapData(key).next(value);
  }

  private setDefaultApiFetchMapData(key: string) {
    if (!this.apiFetchMap.has(key)) {
      const value = new BehaviorSubject(null);
      this.apiFetchMap.set(key, value);
    }
  }

  removeApiFetchMapData(key: string) {
    this.apiFetchMap.delete(key);
  }

  clearApiFetchMapData() {
    this.apiFetchMap.clear();
  }
}
