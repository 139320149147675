import { Component, OnInit } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { BaseComponent } from '#components/core/base/base.component';
import { ComponentService } from '#services/component.service';
import { Overlay } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { DEFAULT_APP_TITLE } from '#utils/const';
import { LoaderService } from '#services/loader.service';
import { HttpErrorHandler } from '#services/http-error-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  spinner = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });
  constructor(
    componentService: ComponentService,
    private overlay: Overlay,
    private loaderService: LoaderService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    super(componentService);
    this.loaderService.isLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        if (bool) {
          setTimeout(() => {
            if (!this.spinner.hasAttached()) {
              this.spinner.attach(new ComponentPortal(MatSpinner));
            }
          });
        } else {
          setTimeout(() => {
            this.spinner.detach();
          });
        }
      });
  }

  ngOnInit(): void {
    this.titleService.setTitle(DEFAULT_APP_TITLE);
    this.subscribeUntilDestroy(
      this.service.loader.isLanguageLoaded,
      (loaded: boolean) => {
        if (!loaded) {
          this.router.navigate(['loader']);
        }
      }
    );

    this.clearApiFetchMap();
  }

  private clearApiFetchMap() {
    this.subscribeUntilDestroy(this.service.router.events, (event) => {
      if (event instanceof NavigationStart) {
        this.httpErrorHandler.clearApiFetchMapData();
      }
    });
  }
}
