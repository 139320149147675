import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { User } from '#models/user.model';
import { ComponentService } from '#services/component.service';
import { AuthService } from '#services/auth.service';
import { UserProfileService } from '#services/user-profile.service';
import { FragmentComponent } from '#components/core/fragment/fragment.component';
import { ChangePasswordDialogComponent } from '#components/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends FragmentComponent implements OnInit {
  current: User;
  constructor(
    private componentService: ComponentService,
    private authService: AuthService,
    profileService: UserProfileService
  ) {
    super(componentService);
    this.current = profileService.current;
  }

  ngOnInit(): void {}

  logout() {
    this.authService.endSession();
  }

  toggleSidenav(): void {
    this.componentService.layout.toggleSidenav();
  }

  openChangePasswordDialog() {
    this.dialogService.showDialog(ChangePasswordDialogComponent, {
      width: '50%',
    });
  }
}
