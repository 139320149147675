import { Injectable } from '@angular/core';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { FileUpload } from '#models/file-upload.model';

@Injectable()
export class FileRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  upload(file: File): Observable<ResponsePayload<FileUpload>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post('/admin/upload', formData, {}, true);
  }
}
