import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core';
import { ComponentService } from '#services/component.service';
import { TreeComponent } from '#components/core/tree/tree.component';
import { NavMenuItem } from '#interfaces/nav-menu-item.interface';
import { MENU_ITEMS } from '#utils/const';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavComponent
  extends TreeComponent<NavMenuItem>
  implements OnInit
{
  @Input() isMinimized: boolean;
  readonly menuItems: NavMenuItem[] = MENU_ITEMS;
  private subEntered = false;
  private subOpened = false;
  private prevButtonTrigger: MatMenuTrigger;

  constructor(componentService: ComponentService) {
    super(componentService);
  }

  ngOnInit() {
    this.setData(this.menuItems);
  }

  openMenu(trigger: MatMenuTrigger): void {
    if (this.isMinimized) {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.subEntered = false;
        trigger?.openMenu();
      } else {
        this.subOpened = true;
        this.prevButtonTrigger = trigger;
        if (!this.subEntered) {
          trigger?.openMenu();
        }
      }
    }
  }

  closeMenu(trigger: MatMenuTrigger): void {
    if (this.isMinimized) {
      setTimeout(() => {
        if (!this.subEntered) {
          trigger?.closeMenu();
        } else {
          this.subOpened = false;
        }
      }, 100);
    }
  }

  keepOpen(): void {
    this.subEntered = true;
  }

  removeOpen(trigger: MatMenuTrigger): void {
    setTimeout(() => {
      this.subEntered = false;
      if (!this.subOpened) {
        trigger?.closeMenu();
      }
    }, 80);
  }
}
