import { FormFragmentComponent } from '#components/core/form-fragment/form-fragment.component';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PasswordEdit } from '#interfaces/auth.interface';
import { FormBuilder, Validators } from '@angular/forms';
import { ComponentService } from '#services/component.service';
import { MAX_CHAR_COUNT_PASSWORD } from '#utils/const';
import { isEqualValidator } from '#validators/isEqual.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent
  extends FormFragmentComponent<PasswordEdit>
  implements OnInit {
  private onDisplayCurrentPassword = false;
  private onDisplayNewPassword = false;
  private onDisplayNewPasswordConfirm = false;
  showPassword = 'text';
  hiddenPassword = 'password';
  iconShowPassword = 'visibility';
  iconDisablePassword = 'visibility_off';
  constructor(
    protected formBuilder: FormBuilder,
    componentService: ComponentService
  ) {
    super(formBuilder, componentService);
  }
  maxPasswordCount = MAX_CHAR_COUNT_PASSWORD;
  validatorPassword = [
    Validators.maxLength(MAX_CHAR_COUNT_PASSWORD),
    this.emptyTextValidator,
    Validators.required,
  ];

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        currentPassword: ['', this.validatorPassword],
        newPassword: ['', this.validatorPassword],
        newPasswordConfirm: ['', this.validatorPassword],
      },
      { validators: isEqualValidator('newPassword', 'newPasswordConfirm') }
    );
  }

  handleInputPassword(keyControl: string, type: boolean): string {
    if (type) {
      return this[keyControl] ? this.showPassword : this.hiddenPassword;
    }
    return this[keyControl] ? this.iconShowPassword : this.iconDisablePassword;
  }

  handleToggleShowPassword(name: string, event?: Event) {
    event.preventDefault();
    this[name] = !this[name];
  }
}
