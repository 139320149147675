<ng-container
  *ngIf="!isNullOrUndefined(isFetchDone$ | async) && !(isFetchDone$ | async)"
>
  <mat-card>
    <div class="px-16 py-24 text-center">
      <h3 class="mat-subheading-1">{{ "message.disconnect" | trans }}</h3>
      <button (click)="onRefresh()" mat-flat-button color="primary">
        {{ "actions.reload" | trans }}
      </button>
    </div>
  </mat-card>
</ng-container>

<ng-container *ngIf="!key || (isFetchDone$ | async)">
  <ng-container *ngIf="isEmpty">
    <mat-card>
      <div class="px-16 py-24 text-center">
        <h3 class="mat-subheading-1">{{ "message.empty_data" | trans }}</h3>
      </div>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="!isEmpty">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>
</ng-container>
