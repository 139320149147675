import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { MessageRoom, NewMessage } from '#models/message.model';
import { ResponsePayload } from '#interfaces/response-payload.interface';
@Injectable()
export class MessageRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  create(message: NewMessage): Observable<ResponsePayload<any>> {
    const newMessage = new MessageRoom().mapToAPI(message);
    return this.httpClient.post('/admin/messages', newMessage);
  }
}
