<form class="mb-5" [formGroup]="formGroup">
  <div [class]="getViewName">
    <div *ngFor="let language of languages">
      <div class="text-right mat-small">
        {{ getInputLength(language, "content") }}/{{ MAX_CHAR_COUNT }}
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>
          {{ "facility_indication.name" | trans: { postfix: language } }}
        </mat-label>
        <input
          [required]="isTranslationFieldRequired(language)"
          matInput
          [formControl]="getTranslatableControl(language, 'content')"
        />
        <mat-error>
          <app-validation-error
            [control]="getTranslatableControl(language, 'content')"
          ></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-3">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ "facility_indication.open_time" | trans }}
      </mat-label>
      <input
        formControlName="openTime"
        [mask]="FORMAT_TIME_SETTING.mask"
        [showMaskTyped]="FORMAT_TIME_SETTING.showMaskTyped"
        [validation]="FORMAT_TIME_SETTING.validation"
        [dropSpecialCharacters]="FORMAT_TIME_SETTING.dropSpecialCharacters"
        matInput
        required
      />
      <mat-error>
        <app-validation-error [control]="getControl('openTime')">
        </app-validation-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ "facility_indication.close_time" | trans }}
      </mat-label>
      <input
        formControlName="closeTime"
        [mask]="FORMAT_TIME_SETTING.mask"
        [showMaskTyped]="FORMAT_TIME_SETTING.showMaskTyped"
        [validation]="FORMAT_TIME_SETTING.validation"
        [dropSpecialCharacters]="FORMAT_TIME_SETTING.dropSpecialCharacters"
        matInput
        required
      />
      <mat-error>
        <app-validation-error [control]="getControl('closeTime')">
        </app-validation-error>
      </mat-error>
    </mat-form-field>
  </div>

  <mat-radio-group
    color="primary"
    formControlName="isUpdateManually"
    class="flex justify-around"
    (change)="handleValueChange()"
  >
    <mat-radio-button
      *ngFor="let status of updateOptions"
      [value]="status?.value"
    >
      {{ status?.name | trans }}
    </mat-radio-button>
  </mat-radio-group>

  <div *ngIf="isUpdateManually">
    <p class="mt-5">{{ "facility_indication.status" | trans }}:</p>
    <mat-radio-group
      color="primary"
      formControlName="statusManual"
      class="flex justify-around"
    >
      <mat-radio-button
        *ngFor="let status of facilityStatus"
        [value]="+status?.value"
      >
        {{ status?.name | trans }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="!isUpdateManually">
    <div class="text-right mat-small">
      {{ getLength("sensorKey") }}/{{ MAX_CHAR_COUNT }}
    </div>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label> {{ "facility_indication.sensor_key" | trans }} </mat-label>
      <input matInput formControlName="sensorKey" />
      <mat-error>
        <app-validation-error
          [control]="getControl('sensorKey')"
        ></app-validation-error>
      </mat-error>
    </mat-form-field>
    <div>
      <span>{{ "facility_indication.uncrowded" | trans }}: 0 ~ </span>
      <mat-form-field class="w-6/12" appearance="outline">
        <input
          appOnlyNumber
          [floating]="0"
          [decimal]="MAX_DIGIT_COUNT"
          [min]="1"
          (ngModelChange)="handleErrorMaxSize()"
          matInput
          formControlName="minSize"
        />
        <mat-error>
          <app-validation-error
            [control]="getControl('minSize')"
          ></app-validation-error>
        </mat-error>
      </mat-form-field>

      <span class="text-right mat-small">
        {{ getLength("minSize") }}/{{ MAX_DIGIT_COUNT }}
      </span>
    </div>
    <div>
      <span
        >{{ "facility_indication.many_people" | trans }}:
        {{ getValue("minSize") }} ~
      </span>
      <mat-form-field class="w-6/12" appearance="outline">
        <input
          matInput
          appOnlyNumber
          [floating]="0"
          [decimal]="MAX_DIGIT_COUNT"
          formControlName="maxSize"
        />
        <mat-error>
          <app-validation-error
            [control]="getControl('maxSize')"
          ></app-validation-error>
        </mat-error>
      </mat-form-field>
      <span class="text-right mat-small">
        {{ getLength("maxSize") }}/{{ MAX_DIGIT_COUNT }}
      </span>
    </div>
    <p>{{ getCrowdedTitle }}</p>
  </div>
</form>
