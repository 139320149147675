import { Model } from './model';
import { Mapper } from '#interfaces/mapper.interface';
import { PaginateOptionEdit, PaginateOption } from './pagination.model';
import { toSnakeCase } from '#utils/helpers';

export class Room extends Model implements Mapper {
  id: number;
  name: string;
  phoneUsername: string;
  phonePassword: string;
  languageCode: string;
  lastCheckout: string;
  wifi: string;
  wifiPassword: string;
  pivot?: Pivot;
  isActive?: boolean;
  deviceToken?: string;
  mapFromAPI(room: any): Room {
    this.name = room?.name;
    this.lastCheckout = room?.last_checkout;
    this.id = room?.id;
    this.phoneUsername = room?.phone_username;
    this.phonePassword = room?.phone_password;
    this.wifi = room?.wifi;
    this.wifiPassword = room?.wifi_password;
    this.isActive = !!room?.is_active;
    this.languageCode = room?.language_code;
    this.deviceToken = room?.device_token;
    this.pivot = new Pivot().mapFromAPI(room?.pivot);
    return this;
  }

  mapPivotFromApi<T extends Mapper>(pivotModel: T, pivotData: any): Room {
    this.pivot = pivotModel.mapFromAPI(pivotData) as Pivot;
    return this;
  }
}

export class RoomEdit extends Model implements Mapper {
  'name': string;
  'language_code': string;
  'is_active': boolean;
  'wifi': string;
  'wifi_password': string;
  mapToAPI(room: Partial<Room>): RoomEdit {
    this.name = room?.name;
    this.language_code = room?.languageCode;
    this.is_active = room?.isActive;
    this.wifi_password = room?.wifiPassword;
    this.wifi = room?.wifi;
    return this;
  }
}

export class RoomAccountEdit extends Model implements Mapper {
  'phone_username': string;
  'phone_password': string;

  mapToAPI(room: Partial<Room>): RoomAccountEdit {
    this.phone_username = room?.phoneUsername;
    this.phone_password = room?.phonePassword;
    return this;
  }
}

interface ItemPaginateOption extends PaginateOption {
  search?: {
    name?: string;
  };
}

export class RoomPaginationOption extends PaginateOptionEdit {
  mapToAPI(paginateOption: ItemPaginateOption): RoomPaginationOption {
    super.mapToAPI(paginateOption);
    const search = paginateOption?.search;
    const sort = paginateOption?.sort;
    if (search?.name) {
      this.setSearch('name', search?.name);
    }
    if (sort?.prop) {
      this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    }
    return this;
  }
}

export class Pivot implements Mapper {
  roomId: number;
  messageId: number;
  isRead: boolean;
  mapFromAPI(pivot: any): Pivot {
    this.roomId = pivot?.room_id;
    this.messageId = pivot?.message_id;
    this.isRead = !!pivot?.is_read;
    return this;
  }
}
