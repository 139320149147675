import { Injectable } from '@angular/core';
import { User } from '#models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  public current: User;

  constructor() { }
}
