import { Injectable } from '@angular/core';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { map } from 'rxjs/operators';
import {
  Setting,
  SettingEdit,
  SettingExtensionPhoneEdit,
} from '#models/setting.model';
import { existOrEmptyArray } from '#utils/helpers';
import { LoaderService } from '#services/loader.service';
@Injectable()
export class SettingRepository extends Repository {
  constructor(httpClient: AppHttpClient, private loaderService: LoaderService) {
    super(httpClient);
  }

  getSettings(): Observable<Setting> {
    return this.httpClient.get('/admin/settings').pipe(
      map((payload: ResponsePayload<SettingEdit>) => {
        const newSetting = new SettingEdit();
        for (const setting of existOrEmptyArray(payload?.data)) {
          newSetting[setting?.key] = setting?.value;
        }
        const settingResponse = new Setting().mapFromAPI(newSetting);
        this.loaderService.setting = settingResponse;
        return settingResponse;
      })
    );
  }

  update(setting: Setting): Observable<ResponsePayload<any>> {
    const newSetting = new SettingEdit().mapToAPI(setting);
    return this.httpClient.post('/admin/settings', newSetting);
  }

  updateRoomAccountSetting(setting: Setting): Observable<ResponsePayload<any>> {
    const newSetting = new SettingExtensionPhoneEdit().mapToAPI(setting);
    return this.httpClient.put('/admin/settings/phone-extension', newSetting);
  }
}
