import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentService } from '#services/component.service';
import { FragmentComponent } from '../fragment/fragment.component';
import { NavigationStart } from '@angular/router';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent extends FragmentComponent implements OnInit {
  @Input() dialogTitle: string;
  @Input() actionAlign: 'start' | 'center' | 'end';
  @Input() backButton = 'actions.cancel';
  @ViewChild('dialogElement', { read: ElementRef, static: true })
  dialogContent: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    componentService: ComponentService,
  ) {
    super(componentService);
  }

  ngOnInit() {
    this.subscribeUntilDestroy(this.router.events, (eventType) => {
      if (eventType instanceof NavigationStart) {
        this.dialogRef.close();
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  scrollTop() {
    this.dialogContent.nativeElement.children[1].scrollTo({ top: 0 });
  }
}
