import { FormFragmentComponent } from '#components/core/form-fragment/form-fragment.component';
import { FacilityIndication } from '#models/facility-inducation.model';
import { ComponentService } from '#services/component.service';
import {
  DEFAULT_LANGUAGE,
  MAX_CHAR_COUNT,
  MAX_DIGIT_COUNT,
  FACILITY_STATUS,
  flag,
  FORMAT_TIME_SETTING,
  REGEX,
  FACILITY_DEFAULT,
} from '#utils/const';
import { isNullOrUndefined } from '#utils/helpers';
import { notEmpty } from '#validators/notEmpty.validator';
import { customPatternValidator } from '#validators/pattern.validator';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-create-or-edit',
  templateUrl: './create-or-edit.component.html',
  styleUrls: ['./create-or-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateOrEditComponent
  extends FormFragmentComponent<FacilityIndication>
  implements OnInit {
  MAX_CHAR_COUNT = MAX_CHAR_COUNT;
  MAX_DIGIT_COUNT = MAX_DIGIT_COUNT;
  FORMAT_TIME_SETTING = FORMAT_TIME_SETTING;
  facilityStatus = [
    ...Object.keys(FACILITY_STATUS)
      .filter((status) => +status !== FACILITY_DEFAULT.CLOSE_TIME)
      .map((key) => {
        return { value: key, name: FACILITY_STATUS[key] };
      }),
  ];

  updateOptions = [
    { name: 'facility_indication.update_manual', value: !!flag.yes },
    { name: 'facility_indication.update_auto', value: !!flag.no },
  ];

  constructor(
    componentService: ComponentService,
    protected formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(formBuilder, componentService);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      statusManual: new FormControl(FACILITY_DEFAULT.DEFAULT_VALUE),
      sensorKey: new FormControl(
        '',
        this.createValidator(MAX_CHAR_COUNT, false)
      ),
      status: new FormControl(null),
      minSize: new FormControl(
        FACILITY_DEFAULT.MIN_SIZE,
        this.createValidator(MAX_DIGIT_COUNT, false)
      ),
      maxSize: new FormControl(FACILITY_DEFAULT.MAX_SIZE, [
        ...this.createValidator(MAX_DIGIT_COUNT, false),
        (control: AbstractControl) =>
          Validators.min(this.getValue('minSize') + 1)(control),
      ]),
      translations: this.generateTranslateForm(false, 'content'),
      openTime: [
        '',
        [
          customPatternValidator({
            pattern: REGEX.time,
            invalidMessage: this.trans('message.input_date_invalid'),
          }),
          Validators.required,
        ],
      ],
      closeTime: [
        '',
        [
          customPatternValidator({
            pattern: REGEX.time,
            invalidMessage: this.trans('message.input_date_invalid'),
          }),
          Validators.required,
        ],
      ],
      isUpdateManually: [!!flag.yes],
    });
    this.formGroup.patchValue({ ...this.data });
  }

  handleValueChange() {
    const {
      isUpdateManually,
      statusManual,
      minSize,
      maxSize,
    } = this.formGroup?.value;
    if (isUpdateManually && isNullOrUndefined(statusManual)) {
      this.getControl('statusManual').setValue(FACILITY_DEFAULT.DEFAULT_VALUE);
    }

    if (isUpdateManually && this.getControl('maxSize')?.errors?.min) {
      this.getControl('maxSize').setValue(
        +this.getControl('maxSize')?.errors?.min?.min
      );
    }

    if (
      !isUpdateManually &&
      (isNullOrUndefined(minSize) || isNullOrUndefined(maxSize))
    ) {
      this.formGroup.patchValue({
        maxSize: FACILITY_DEFAULT.MAX_SIZE,
        minSize: FACILITY_DEFAULT.MIN_SIZE,
      });
    }
  }

  get getViewName(): string {
    const value = this.languages.length % 2 === 0;
    return value ? 'grid grid-cols-2 gap-3' : 'grid grid-cols-1 gap-1';
  }

  private createValidator(count: number, isRequired: boolean): ValidatorFn[] {
    const newValidator = [notEmpty] as ValidatorFn[];
    if (!!count) {
      newValidator.push(Validators.maxLength(count));
    }
    if (isRequired) {
      newValidator.push(Validators.required);
    }
    return newValidator;
  }

  isDefaultLanguage(target: string): boolean {
    return target === DEFAULT_LANGUAGE;
  }

  getLength(target: string): number {
    return this.formGroup?.value[target]?.toString().length || 0;
  }

  get isUpdateManually(): boolean {
    return this.formGroup?.get('isUpdateManually')?.value;
  }

  handleErrorMaxSize() {
    this.getControl('maxSize')?.markAsTouched();
    this.getControl('maxSize')?.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();
  }

  getValue(target: string): number {
    return +this.formGroup?.value[target] + 1;
  }

  getControl(target: string): FormControl {
    return this.formGroup?.get(target) as FormControl;
  }

  get getCrowdedTitle(): string {
    return this.transObject('facility_indication.crowded_description', {
      count: this.getValue('maxSize'),
    });
  }

  get formValue(): FacilityIndication {
    return {
      ...this.formGroup?.value,
    };
  }
}
