import { Model } from './model';
import { existOrEmptyArray } from '#utils/helpers';
import { Mapper } from '#interfaces/mapper.interface';
import {
  MessageTranslation,
  TranslationModel,
} from '#models/translation.model';
import { Room, Pivot } from '#models/room.model';

export class Message extends Model implements Mapper {
  content: string;
  rooms: Room[];
  createdAt: string;
  updatedAt: string;
  contentMessage: TranslationModel[];
  isOverWrite: boolean;

  mapFromAPI(message: any): Message {
    this.isOverWrite = message?.is_overwrite_content;
    this.content = message?.content;
    this.rooms = existOrEmptyArray(message?.rooms).map((room) => {
      let item = new Room().mapFromAPI(room);
      item = item.mapPivotFromApi<Pivot>(new Pivot(), room?.pivot);
      return item;
    });
    this.updatedAt = message?.updated_at || null;
    this.contentMessage = existOrEmptyArray(message?.content_translations).map(
      (content) => {
        const item = new TranslationModel().mapFromAPI(content);
        return item;
      }
    );
    this.createdAt = message?.created_at || null;
    return this;
  }
}

export interface NewMessage {
  rooms: number[];
  contentMessage: { [key: string]: { content: string } }[];
}

export class MessageRoom implements Mapper {
  'content_message'?: ContentMessage[];
  'rooms'?: number[];

  mapToAPI(messageRoom: NewMessage): MessageRoom {
    this.content_message = Object.keys(messageRoom?.contentMessage).map(
      (key) => {
        return new ContentMessage().mapToAPI({
          languageCode: key,
          content: messageRoom?.contentMessage[key]?.content,
        });
      }
    );
    this.rooms = messageRoom?.rooms;
    return this;
  }
}

export class ContentMessage implements Mapper {
  'language_code': string;
  'content': string;
  mapToAPI(translation: MessageTranslation): ContentMessage {
    this.language_code = translation?.languageCode;
    this.content = translation?.content;
    return this;
  }
}
