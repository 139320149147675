import { TreeComponent } from '#components/core/tree/tree.component';
import { ComponentService } from '#services/component.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { BaseCategory } from '#models/base-category.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { spliceArray } from '#utils/helpers';
import { InformationCategoryRepository } from '#repositories/information-category.repository';
import { ServiceCategoryRepository } from '#repositories/service-category.repository';
import { CATEGORY_TYPE, NULL_VALUE } from '#utils/const';
import { CategoryRepository } from '#interfaces/category-repository.interface';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MessageService } from '#services/message.service';
import { CategoryFormDialogComponent } from '../category-form-dialog/category-form-dialog.component';
import { CategoryFormData } from '#interfaces/category-detail.interface';
import { ResponsePayload } from '#interfaces/response-payload.interface';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTreeComponent<T extends BaseCategory>
  extends TreeComponent<BaseCategory>
  implements OnInit {
  @Input() categoryType: CATEGORY_TYPE;
  @Input() apiUri: string;
  private categoryRepository: CategoryRepository;
  categories: T[];

  constructor(
    private informationCategoryRepository: InformationCategoryRepository,
    private serviceCategoryRepository: ServiceCategoryRepository,
    private messageService: MessageService,
    componentService: ComponentService
  ) {
    super(componentService);
  }

  ngOnInit(): void {
    switch (this.categoryType) {
      case CATEGORY_TYPE.INFORMATION:
        this.categoryRepository = this.informationCategoryRepository;
        break;
      case CATEGORY_TYPE.SERVICE:
        this.categoryRepository = this.serviceCategoryRepository;
        break;
      default:
        this.categoryRepository = this.informationCategoryRepository;
        break;
    }
    this.fetchCategories();
  }

  fetchCategories(): void {
    this.subscribeOnce(
      this.categoryRepository.getCategories(),
      (categories) => {
        this.categories = categories as T[];
        this.setData(this.categories);
      }
    );
  }

  onOpenFormDialog(category?: T): void {
    const dialogRef = this.dialogService.showDialog(
      CategoryFormDialogComponent,
      {
        width: '50%',
        data: {
          mainCategories: this.categories,
          category,
        },
      }
    );
    this.subscribeUntilDestroy(
      (dialogRef.componentInstance as CategoryFormDialogComponent<T>)
        .categoryFormSubmit,
      (formData: CategoryFormData<T>) => {
        this.subscribeOnce(this.editOrAddCategory(formData), (payload) => {
          this.fetchCategories();
          dialogRef.close();
          this.messageService.showMessage(payload?.message);
        });
      }
    );
  }

  onToggleStatus(category: T, event?: MatSlideToggleChange): void {
    this.subscribeOnce(
      this.categoryRepository.toggleCategoryStatus(category?.id).pipe(
        catchError((error) => {
          if (event) {
            event.source.checked = category?.isActive;
          }
          return throwError(error);
        })
      ),
      (payload) => {
        this.updateToggleStatus(category);
        this.messageService.showMessage(payload?.message);
      }
    );
  }

  async onDelete(category: T): Promise<void> {
    const accept = await this.dialogService.confirm(
      this.trans('message.confirm_delete')
    );
    if (accept) {
      this.subscribeOnce(
        this.categoryRepository.deleteCategory(category?.id),
        (payload) => {
          this.removeCategory(category);
          this.messageService.showMessage(payload?.message);
        }
      );
    }
  }

  private removeCategory(category: T): void {
    const mainCategories = this.categories;
    const mainIndex = mainCategories.findIndex(
      (mainCategory) => mainCategory?.id === category?.id
    );
    if (mainIndex >= 0) {
      this.categories = spliceArray(
        mainCategories,
        mainIndex,
        mainCategories[mainIndex]?.children?.map((child) => {
          child.isParentActive = true;
          child.parentId = NULL_VALUE;
          return child;
        })
      );
      this.setData(this.categories);
      return;
    }

    for (const mainCategory of mainCategories) {
      const children = mainCategory?.children;
      const childIndex = children.findIndex(
        (childCategory) => childCategory?.id === category?.id
      );
      if (childIndex >= 0) {
        mainCategory.children = spliceArray(children, childIndex);
        this.categories = [...mainCategories];
        this.setData(this.categories);
        break;
      }
    }
  }

  private updateToggleStatus(category: T): void {
    let found = false;
    const categories = this.categories.map((mainCategory) => {
      if (!found) {
        if (mainCategory?.id === category?.id) {
          found = true;
          mainCategory?.children?.forEach((child) => {
            child.isParentActive = !category?.isActive;
          });
          mainCategory.isActive = !category?.isActive;
        } else {
          for (const childCategory of mainCategory?.children) {
            if (childCategory?.id === category?.id) {
              found = true;
              childCategory.isActive = !category?.isActive;
              break;
            }
          }
        }
      }
      return mainCategory;
    });
    this.setData(categories);
  }

  private editOrAddCategory(
    formData: CategoryFormData<T>
  ): Observable<ResponsePayload<any>> {
    if (formData?.isUpdate) {
      return this.categoryRepository.editCategory(
        formData?.id,
        formData?.value
      );
    }
    return this.categoryRepository.addCategory(formData?.value);
  }
}
