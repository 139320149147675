import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorHandler } from './http-error-handler.service';
import { LocalStorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { removeUndefined } from '#utils/helpers';

@Injectable()
export class AppHttpClient {
  static prefix = environment.apiUrl;

  /**
   * AppHttpClient Constructor.
   */
  constructor(
    protected httpClient: HttpClient,
    protected errorHandler: HttpErrorHandler,
    private storageService: LocalStorageService
  ) {}

  public get<T>(uri: string, params = {}, configs: any = {}): Observable<T> {
    params = removeUndefined(params);
    const httpParams = this.generateHttpParams(params);
    return this.httpClient
      .get<T>(this.prefixUri(uri), {
        reportProgress: configs.reportProgress,
        params: httpParams,
        headers: this.generateHttpHeaders(configs.headers),
      })
      .pipe(
        catchError((err, caught) => {
          this.errorHandler.setApiFetchMapData(uri, false);
          return this.handleError(err);
        }),
        tap(() => {
          this.errorHandler.setApiFetchMapData(uri, true);
        })
      );
  }

  public post<T>(
    uri: string,
    params: object = null,
    configs: any = {},
    noContentType = false
  ): Observable<T> {
    params = removeUndefined(params);
    return this.httpClient
      .post<T>(this.prefixUri(uri), params, {
        reportProgress: configs.reportProgress,
        headers: this.generateHttpHeaders(configs.headers, noContentType),
      })
      .pipe(catchError((err, caught) => this.handleError(err)));
  }

  public put<T>(
    uri: string,
    params: object = {},
    noContentType = false
  ): Observable<T> {
    params = removeUndefined(params);
    return this.httpClient
      .put<T>(this.prefixUri(uri), params, {
        headers: this.generateHttpHeaders({}, noContentType),
      })
      .pipe(catchError((err, caught) => this.handleError(err)));
  }

  public patch<T>(
    uri: string,
    params: object = {},
    configs: any = {}
  ): Observable<T> {
    params = removeUndefined(params);
    return this.httpClient
      .patch<T>(this.prefixUri(uri), params, {
        headers: this.generateHttpHeaders(configs.headers),
      })
      .pipe(catchError((err, caught) => this.handleError(err)));
  }

  public delete<T>(uri: string, params: object = {}): Observable<T> {
    params = removeUndefined(params);
    return this.httpClient
      .delete<T>(this.prefixUri(uri), {
        headers: this.generateHttpHeaders(),
      })
      .pipe(catchError((err, caught) => this.handleError(err)));
  }

  /**
   * Prefix specified uri with backend API prefix.
   */
  private prefixUri(uri: string) {
    if (uri.includes('http')) {
      return uri;
    }
    return AppHttpClient.prefix + uri;
  }

  /**
   * Generate http params for GET request.
   */
  private generateHttpParams(params: object) {
    let httpParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.append(key, params[key]);
      }
    }

    return httpParams;
  }

  // TODO: request interceptor
  private generateHttpHeaders(headerInfo?: object, noContentType = false) {
    let headers = new HttpHeaders();
    if (!noContentType) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (this.storageService.get('access_token')) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.storageService.get('access_token')}`
      );
    }

    if (headerInfo) {
      for (const header of Object.keys(headerInfo)) {
        if (headerInfo[header]) {
          headers = headers.set(header, headerInfo[header]);
        } else {
          headers = headers.delete(header);
        }
      }
    }

    return headers;
  }

  private handleError(error: HttpErrorResponse) {
    return this.errorHandler.handle(error);
  }
}
