import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ComponentService } from '#services/component.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MessageService } from '#services/message.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnInit, OnDestroy {
  protected destroy$ = new Subject<void>();
  protected session$: Subject<void>;

  constructor(protected service: ComponentService) {}

  ngOnInit(): void {}

  protected get router() {
    return this.service.router;
  }

  public get activatedRoute(): ActivatedRoute {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  protected get queryParams() {
    return this.activatedRoute.queryParams;
  }

  protected get routeParams(): Params {
    return this.activatedRoute.snapshot.params;
  }

  protected get dialogService() {
    return this.service.dialog;
  }

  protected get messageService(): MessageService {
    return this.service.message;
  }

  protected get titleService(): Title {
    return this.service.title;
  }

  protected trans(message: string, data?: any): string {
    return this.service.translate.translate(message, data);
  }

  protected transObject(message: string, data?: any): any {
    return this.service.translate.translateObject(message, data);
  }

  protected redirect(path: any, queryParams?: any, replaceUrl = false) {
    const commands = path instanceof Array ? path : [path];
    this.router.navigate(commands, { queryParams, replaceUrl });
  }

  keepOrder(current: any, after: any) {
    return current;
  }

  protected preDestroy() {}

  private doDestroy() {
    this.destroy$.next();
    if (this.session$ && !this.session$.closed) {
      this.session$.next();
      this.session$.complete();
    }
  }

  ngOnDestroy() {
    this.preDestroy();
    this.doDestroy();
  }

  protected subscribeOnce<T>(
    observable$: Observable<T>,
    callback: (data: T) => void
  ) {
    return observable$.pipe(take(1)).subscribe((data) => {
      callback(data);
    });
  }

  protected subscribeUntilDestroy<T>(
    observable$: Observable<T>,
    callback: (data: T) => void
  ) {
    return observable$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      callback(data);
    });
  }

  protected reCreateSession(): Subject<void> {
    if (this.session$ && !this.session$.closed) {
      this.session$.next();
      this.session$.complete();
    }
    this.session$ = new Subject<void>();
    return this.session$;
  }
}
