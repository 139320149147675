import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '#components/share/confirm-dialog/confirm-dialog.component';
import { WarningDialogComponent } from '#components/share/warning-dialog/warning-dialog.component';
import { ImageCropperDialogComponent } from '#components/share/image-cropper-dialog/image-cropper-dialog.component';
import { finalize, take } from 'rxjs/operators';
import { ImageCropperResponse } from '#interfaces/image-cropper-data.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  showDialog(component: any, options?: any) {
    return this.dialog.open(component, options);
  }

  confirm(message: string, payload?: any) {
    const data = { content: message, payload };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data,
      minWidth: '300px',
    });
    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }

  warning(message: string) {
    const data = { content: message };
    return this.dialog.open(WarningDialogComponent, {
      data,
      minWidth: '300px',
    });
  }
}
