import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { ComponentService } from '#services/component.service';
import {
  PaginateData,
  PaginateOption,
  DatatablePageInfo,
  DatatableSortInfo,
  PaginateOptionEdit,
  Pagination,
  PaginateSearchBody,
} from '#models/pagination.model';
import {
  DEFAULT_START_PAGE,
  DEFAULT_PAGE_SIZE,
  PaginableType,
} from '#utils/const';
import { DatatableMessage } from '#interfaces/datatable-message.interface';
import { Mapper } from '#interfaces/mapper.interface';
import { PaginationRepository } from '#repositories/pagination.repository';

@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: ['./table-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePageComponent<T extends Mapper>
  extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent) datatable: DatatableComponent;
  datatablePageInfo: DatatablePageInfo;
  paginateOption: PaginateOption = {
    page: DEFAULT_START_PAGE,
    perPage: DEFAULT_PAGE_SIZE, // default page size,
    match: {},
  };
  paginateData: PaginateData<T>;
  datatableMessage: DatatableMessage;
  paginableType: PaginableType;
  factory: () => T;
  paginateOptionEditFactory = () => new PaginateOptionEdit();

  constructor(
    protected paginationRepository: PaginationRepository<T>,
    protected changeDetectorRef: ChangeDetectorRef,
    componentService: ComponentService
  ) {
    super(componentService);
  }

  ngOnInit(): void {
    this.preInit();
    this.doInit();
  }

  protected doInit(): void {
    this.datatableMessage = this.transObject('datatable_msg');
    this.loadDatasource();
  }

  protected preInit(): void {}

  loadDatasource(): void {
    this.paginationRepository
      .paginate(
        this.paginableType,
        this.paginateOptionEditFactory().mapToAPI(this.paginateOption)
      )
      .pipe(takeUntil(this.reCreateSession()))
      .subscribe((payload) => {
        this.paginateData = new PaginateData<T>().mapFromAPI(
          {
            pagination: payload?.pagination,
            data: payload?.data,
          },
          this.factory
        );
        if (
          this.paginateData?.data?.length === 0 &&
          this.paginateData?.pagination?.page > 0
        ) {
          this.paginateOption.page -= 1;
          this.loadDatasource();
          return;
        }
        this.changeDetectorRef.markForCheck();
        this.onDatasourceChanged();
      });
  }

  onDatasourceChanged(): void {
    // Perform some actions with the new data
    // Limit function size of loadDatasource()
  }

  onPageChange(pageInfo: DatatablePageInfo): void {
    this.paginateOption.page = pageInfo.offset + 1;
    this.loadDatasource();
  }

  onSort(sortInfo: DatatableSortInfo): void {
    const sorter = sortInfo?.sorts[0];
    this.paginateOption.sort = {
      prop: sorter?.prop,
      dir: sorter?.dir,
    };
    this.resetPage();
  }

  onSearch(searchBody: PaginateSearchBody): void {
    const searchBodyUpdated = {
      match: {
        ...this.paginateOption?.match,
        ...searchBody?.match,
      },
      search: {
        ...this.paginateOption?.search,
        ...searchBody?.search,
      },
    };
    this.paginateOption = { ...this.paginateOption, ...searchBodyUpdated };
    this.resetPage();
  }

  resetPage(): void {
    this.paginateOption.page = 1;
    this.loadDatasource();
    if (this.datatable) {
      this.datatable.offset = 0;
    }
  }

  getItemIndex(index: number): number {
    return index + 1 + this.pagination?.page * this.pagination?.perPage;
  }

  get dataSource(): T[] {
    return this.paginateData?.data;
  }

  get pagination(): Pagination {
    return this.paginateData?.pagination;
  }
}
