import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { Mapper } from '#interfaces/mapper.interface';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { PaginateOptionEdit, PaginateData } from '#models/pagination.model';
import { PaginableType, PAGINABLE_MAP } from '#utils/const';

@Injectable()
export class PaginationRepository<T extends Mapper> extends Repository {
  constructor(protected httpClient: AppHttpClient) {
    super(httpClient);
  }

  paginate(
    paginableType: PaginableType,
    paginateOption: PaginateOptionEdit
  ): Observable<ResponsePayload<PaginateData<T>>> {
    return this.httpClient.get(PAGINABLE_MAP[paginableType], paginateOption);
  }
}
