import { Expandable } from '#interfaces/expandable.interface';
import { Mapper } from '#interfaces/mapper.interface';
import { DEFAULT_LANGUAGE, NULL_VALUE } from '#utils/const';
import { existOrEmptyArray, isNullOrUndefined } from '#utils/helpers';
import { Model } from './model';
import {
  BaseCategoryEditTranslation,
  BaseCategoryTranslation,
} from './translation.model';

export class BaseCategory
  extends Model
  implements Mapper, Expandable<BaseCategory> {
  name: string;
  children: BaseCategory[];
  isActive?: boolean;
  isParentActive?: boolean;
  translations: { [key: string]: BaseCategoryTranslation };
  parentId: number;

  level: number;
  expandable: boolean;

  mapFromAPI(category: any): BaseCategory {
    this.id = category?.id;
    this.createdAt = category?.created_at;
    this.updatedAt = category?.updated_at;
    this.name = category?.name;
    this.children = existOrEmptyArray(category?.children).map(
      (childCategory) => {
        const child = new BaseCategory().mapFromAPI(childCategory);
        child.isParentActive = category?.is_active;
        return child;
      }
    );
    this.isActive = !!category?.is_active;
    this.isParentActive = isNullOrUndefined(category?.parent)
      ? true
      : category?.parent?.is_active;
    this.translations = {
      [DEFAULT_LANGUAGE]: {
        name: category?.name,
      },
    };
    existOrEmptyArray(category?.name_translations).forEach((name) => {
      this.translations[name?.language_code] = {
        name: name?.content,
      };
    });
    this.parentId = !isNullOrUndefined(category?.parent_id)
      ? category?.parent_id
      : NULL_VALUE;

    return this;
  }
}

export class BaseCategoryEdit extends Model implements Mapper {
  'name'?: string;
  'is_active'?: number;
  'parent_id'?: number;
  'translations'?: BaseCategoryEditTranslation[];

  mapToAPI(category: BaseCategory): BaseCategoryEdit {
    this.is_active = +category?.isActive;
    this.parent_id =
      category?.parentId === NULL_VALUE ? null : category?.parentId;
    const translations = { ...category?.translations } || {};
    const defaultTranslation = translations[DEFAULT_LANGUAGE];
    this.name = defaultTranslation?.name;
    delete translations[DEFAULT_LANGUAGE];

    this.translations = Object.keys(translations).map((key) =>
      new BaseCategoryEditTranslation(key).mapToAPI({
        name: translations[key]?.name,
      })
    );
    return this;
  }
}
