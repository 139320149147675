import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BaseComponent } from '#components/core/base/base.component';
import { ComponentService } from '#services/component.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent extends BaseComponent implements OnInit {

  constructor(componentService: ComponentService) {
    super(componentService);
   }

  ngOnInit(): void {
  }

}
