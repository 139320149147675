import { Injectable } from '@angular/core';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import {
  SurveyQuestion,
  SurveyQuestionEdit,
} from '#models/survey-question.model';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { map } from 'rxjs/operators';
import { SurveyResult } from '#models/survey-result.model';

@Injectable()
export class SurveyQuestionRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  create(survey: SurveyQuestion): Observable<ResponsePayload<any>> {
    const surveyCreate = new SurveyQuestionEdit().mapToAPI(survey);
    return this.httpClient.post('/admin/survey/questions', surveyCreate);
  }

  update(survey: SurveyQuestion, id: number): Observable<ResponsePayload<any>> {
    const surveyUpdate = new SurveyQuestionEdit().mapToAPI(survey);
    return this.httpClient.put(`/admin/survey/questions/${id}`, surveyUpdate);
  }

  getDetail(id: number): Observable<ResponsePayload<SurveyQuestion>> {
    return this.httpClient.get(`/admin/survey/questions/${id}`);
  }

  getResultDetail(id: number): Observable<SurveyResult> {
    return this.httpClient
      .get(`/admin/survey/results/${id}`)
      .pipe(
        map((payload: ResponsePayload<SurveyResult>) =>
          new SurveyResult().mapFromAPI(payload?.data)
        )
      );
  }

  delete(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/survey/questions/${id}`);
  }
}
