<app-dialog [dialogTitle]="title | trans" actionAlign="center">
  <div dialog-body class="overflow-hidden">
    <app-create-or-edit
      [isUpdate]="isUpdate()"
      [data]="data"
    ></app-create-or-edit>
  </div>
  <div dialog-action>
    <button
      mat-flat-button
      (click)="onSubmit()"
      [disabled]="isDisabledButtonSubmit()"
      color="primary"
    >
      {{ submitButton | trans }}
    </button>
  </div>
</app-dialog>
