import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { ComponentService } from '#services/component.service';
import { FragmentComponent } from '#components/core/fragment/fragment.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent extends FragmentComponent implements OnInit {
  @Input() title: string;

  constructor(componentService: ComponentService) {
    super(componentService);
  }

  ngOnInit(): void {}
}
