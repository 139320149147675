import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[appMatTabScrollRetainer]',
})
export class MatTabScrollRetainerDirective implements OnInit, OnDestroy {
  @Input() scrollBody: Element;
  private changes: MutationObserver;
  private lastScrollPosition = 0;
  private subscription: Subscription;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.changes = new MutationObserver(() => this.rollbackScrollPosition());
    this.changes.observe(this.elementRef.nativeElement, {
      childList: true,
      subtree: true,
    });
    if (!this.scrollBody) {
      this.scrollBody =
        document.querySelector('mat-sidenav-content') ||
        document.querySelector('body');
    }
    this.subscription = fromEvent(this.scrollBody, 'scroll').subscribe(() => {
      this.lastScrollPosition = this.scrollBody.scrollTop;
    });
  }

  private rollbackScrollPosition() {
    this.scrollBody.scrollTop = this.lastScrollPosition;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.changes.disconnect();
  }
}
