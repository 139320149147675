import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogComponent } from '#components/core/dialog/dialog.component';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { CROPPER_RATIO_1_1 } from '#utils/const';
import { ImageCropperInput } from '#interfaces/image-cropper-data.interface';
import { TranslateService } from '#services/translate.service';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperDialogComponent implements OnInit {
  imageChangedEvent: Event;
  imageData: string;
  cropperRatio: number;
  cropperMinHeight: number;
  isCropperReady = false;
  imageExt: string;
  private croppedImage: File;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperInput,
    public dialogRef: MatDialogRef<DialogComponent>
  ) {}

  ngOnInit(): void {
    this.doBasicInit();
  }

  doBasicInit(): void {
    this.imageChangedEvent = this.data.event;
    this.cropperRatio = this.data.cropperRatio || CROPPER_RATIO_1_1;
    this.imageExt = this.data.imageExt || 'png';
    if (this.imageExt === 'jpg') {
      this.imageExt = 'jpeg';
    }
  }

  onCropperReady(): void {
    this.isCropperReady = true;
  }

  onImageCropped(event: ImageCroppedEvent): void {
    this.imageData = event.base64;
    this.croppedImage = new File(
      [base64ToFile(event.base64)],
      `image.${this.imageExt}`,
      {
        type: `image/${this.imageExt}`,
      }
    );
  }

  onFinishCropImage(): void {
    this.dialogRef.close({
      success: true,
      data: {
        file: this.croppedImage,
        image: this.imageData,
      },
    });
  }

  onImageFailed(): void {
    const data = {
      content: this.translateService.translate('message.load_image_error'),
    };
    this.dialog.open(WarningDialogComponent, {
      data,
      minWidth: '300px',
    });
    this.dialogRef.close();
  }
}
