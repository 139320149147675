import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private isToggleSidenav = new Subject<any>();
  isToggleSideContent$ = this.isToggleSidenav.asObservable();

  constructor() {}

  toggleSidenav(): void {
    this.isToggleSidenav.next(new Date());
  }
}
