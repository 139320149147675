import { NavMenuItem } from '#interfaces/nav-menu-item.interface';
import { MatDateFormats } from '@angular/material/core';

export const DEFAULT_APP_TITLE = 'Z-Link ホテル管理システム';

export enum flag {
  yes = 1,
  no = 0,
}

export const DEFAULT_START_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const REGEX = {
  ipv4: /^(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){3}$/,
  ipv6: /^(?:(?:[0-9a-fA-F:]){1,4}(?:(?::(?:[0-9a-fA-F]){1,4}|:)){2,7})+$/,
  time: /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
  fqdn: new RegExp(
    '^(?!://)(?=.{1,255}$)((.{1,63}.){1,127}(?![0-9]*$)[a-z0-9-]+.?)$',
    'i'
  ),
};

export const PHONE_NUMBER_SETTING = {
  mask: '900',
  roomPhoneNumber: '90000000000',
  specialCharacters: [],
};

export const ID_ORDER_FILTER = {
  mask: '90000000000',
  specialCharacters: [],
};

export const FORMAT_TIME_SETTING = {
  mask: 'Hh:m0',
  showMaskTyped: false,
  validation: false,
  dropSpecialCharacters: false,
};

export const IP_ADDRESS_SETTING = {
  showMaskTyped: false,
  dropSpecialCharacters: false,
  pattern: REGEX.ipv4,
  validation: false,
};

export const FORMAT_TIME_STATISTIC = {
  mask: 'd0/M0/0000',
  showMaskTyped: false,
  dropSpecialCharacters: true,
};

export const PRINTER_PORTS = ['8008', '8043'];

export const MOMENT_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const FEATURES_VALUE = {
  isEnableLaundry: 0,
  isEnablePhoneExtension: 1,
  isEnableFacility: 2,
  isEnableGuestRequest: 3,
  isEnableSurvey: 4,
};

export const FEATURES_KEY = [
  ...Object.keys(FEATURES_VALUE).map((setting) => {
    return setting;
  }),
];

export const MAT_DATE_LOCAL_TIMEPICKER = 'ja-JP';

export const MAX_CHAR_COUNT = 255;
export const MAX_CHAR_COUNT_PASSWORD = 255;
export const MIN_SORT_ORDER = 1;
export const MAX_DIGIT_COUNT = 9;
export const DEFAULT_CLEAR_DATA_AT = '12:00';

export const LANGUAGE = {
  JA: 'ja',
  EN: 'en',
  KO: 'ko',
  ZH: 'zh',
};

export const DATA_LANGUAGES = [
  ...Object.keys(LANGUAGE).map((language) => {
    return LANGUAGE[language];
  }),
];

export const QUESTION_SURVEY_TYPE = {
  SINGLE_CHOICE: 0,
  MULTI_CHOICE: 1,
  FREE_TEXT: 2,
};

export const FACILITY_STATUS = {
  0: 'facility_indication.uncrowded',
  1: 'facility_indication.many_people',
  2: 'facility_indication.crowded',
  3: 'facility_indication.closed',
};

export const FACILITY_DEFAULT = {
  DEFAULT_VALUE: 0,
  CLOSE_TIME: 3,
  MIN_SIZE: 1,
  MAX_SIZE: 3,
};

export const DEFAULT_LANGUAGE = 'ja';
export const OTHER_DATA_LANGUAGES = ['ko', 'en', 'zh'];
export const ALL_CATEGORIES_OPTION = -1;
export const ALL_OPTION = -1;
export const DEFAULT_DATE_FORMAT = 'yyyy/MM/dd HH:mm';
export const MIN_PRICE = 0;

export const validImageExtensions = 'jpg, jpeg, png';
export const CROPPER_RATIO_1_1 = 1 / 1;
export const CROPPER_RATIO_3_1 = 3 / 1;
export const NULL_VALUE = Number.NEGATIVE_INFINITY;
export const NOT_EMPTY = /^$|^\s*(\s*\S+\s*)+\s*$/;

export const imageFileSizeFactory = {
  maxSize: 10 * 1024 * 1024,
  get maxSizeMB() {
    return this.maxSize / 1048576;
  },
};

export enum CATEGORY_TYPE {
  INFORMATION = 0,
  SERVICE = 1,
}

export enum PaginableType {
  INFORMATION = 'information',
  LIST_MESSAGE = 'listMessage',
  SURVEY = 'survey',
  ORDER = 'order',
  ROOM = 'room',
  SERVICE_ITEM = 'serviceItem',
  SURVEY_RESULT = 'surveyResult',
  SURVEY_STATISTIC = 'statistics',
  LAUNDRY = 'laundry',
  FACILITY_INDICATION = 'facility_indication',
}

export const PAGINABLE_MAP = {
  informationCategory: '/dummy/category.dummy.json',
  listMessage: `/admin/messages`,
  information: '/admin/informations',
  survey: '/admin/survey/questions/paginate',
  order: '/admin/orders',
  room: '/admin/rooms/paginate',
  serviceItem: '/admin/service-items',
  surveyResult: '/admin/survey/results',
  statistics: '/admin/survey/statistics',
  laundry: '/admin/laundries',
  facility_indication: '/admin/facilities',
};

export const API_URI = {
  informationCategory: '/admin/information-categories',
  information: '/admin/informations',
  message: '/admin/messages',
  survey: '/admin/survey/questions/paginate',
  room: '/admin/rooms/paginate',
  serviceCategory: '/admin/service-categories',
  serviceItem: '/admin/service-items',
  order: '/admin/orders',
  surveyResult: '/admin/survey/results',
  statistics: '/admin/survey/statistics',
  laundry: '/admin/laundries',
  laundryType: '/admin/laundry-types',
  facility_indication: '/admin/facilities',
  setting: '/admin/settings',
};

export const ORDER_STATUS = {
  pending: 0,
  accepted: 1,
  done: 2,
  cancelled: 3,
};

export const MENU_ITEMS: NavMenuItem[] = [
  {
    href: '/information',
    icon: 'info',
    label: 'navigation.information',
    children: [
      {
        href: '/information/categories',
        label: 'actions.manage_category',
      },
    ],
  },
  {
    href: '/messages',
    icon: 'email',
    label: 'navigation.message_list',
  },
  {
    href: '/survey',
    icon: 'question_answer',
    label: 'navigation.survey',
    children: [
      {
        href: '/survey/results',
        label: 'survey.survey_result',
      },
      {
        href: '/survey/statistics',
        label: 'screen_name.survey-statistics',
      },
    ],
  },
  {
    href: '/requests',
    icon: 'room_service',
    label: 'navigation.guest_request',
  },
  {
    href: '/rooms',
    icon: 'meeting_room',
    label: 'navigation.room_management',
  },
  {
    href: '/services',
    icon: 'fastfood',
    label: 'navigation.service_product',
    children: [
      {
        href: '/services/categories',
        label: 'actions.manage_category',
      },
    ],
  },

  {
    href: '/laundry',
    icon: 'local_laundry_service',
    label: 'navigation.laundry',
  },
  {
    href: '/facility-indication',
    icon: 'deck',
    label: 'navigation.facility_indication',
  },
  {
    href: '/room-account',
    icon: 'phone',
    label: 'navigation.room_account',
  },
  {
    href: '/settings',
    icon: 'settings',
    label: 'navigation.setting',
  },
];

export const PRINTER = {
  LANGUAGE: {
    0: 'en',
    1: 'ja',
    2: 'zh-cn',
    3: 'zh_tw',
    4: 'ko',
    5: 'th',
    6: 'vi',
    7: 'mul',
  },
  ALIGN: {
    0: 'ALIGN_LEFT',
    1: 'ALIGN_CENTER',
    2: 'ALIGN_RIGHT',
  },
  BARCODE_TYPE: {
    0: 'BARCODE_UPC_A',
    1: 'BARCODE_UPC_E',
    2: 'BARCODE_EAN13',
    3: 'BARCODE_JAN13',
    4: 'BARCODE_EAN8',
    5: 'BARCODE_JAN8',
    6: 'BARCODE_CODE39',
    7: 'BARCODE_ITF',
    8: 'BARCODE_CODABAR',
    9: 'BARCODE_CODE93',
    10: 'BARCODE_CODE128',
    11: 'BARCODE_GS1_128',
    12: 'BARCODE_GS1_DATABAR_OMNIDIRECTIONAL',
    13: 'BARCODE_GS1_DATABAR_TRUNCATED',
    14: 'BARCODE_GS1_DATABAR_LIMITED',
    15: 'BARCODE_GS1_DATABAR_EXPANDED',
  },
  HRI: {
    0: 'HRI_NONE',
    1: 'HRI_ABOVE',
    2: 'HRI_BELOW',
    3: 'HRI_BOTH',
  },
  FONT: {
    0: 'FONT_A',
    1: 'FONT_B',
    2: 'FONT_C',
    3: 'FONT_D',
    4: 'FONT_E',
    5: 'FONT_SPECIAL_A',
    6: 'FONT_SPECIAL_B ',
  },
  LAYOUT: {
    0: 'LAYOUT_RECEIPT',
    1: 'LAYOUT_RECEIPT_BM',
    2: 'LAYOUT_LABEL',
    3: 'LAYOUT_LABEL_BM',
  },
  CUT: {
    0: 'CUT_NO_FEED',
    1: 'CUT_FEED',
    2: 'CUT_RESERVE',
  },
};
