import { Injectable } from '@angular/core';
import { AppHttpClient } from '#services/app-http-client.service';
import { Repository } from './repository';
import { Laundry, LaundryEdit, LaundryType } from '#models/laundry.model';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { map } from 'rxjs/operators';
import { existOrEmptyArray } from '#utils/helpers';

@Injectable()
export class LaundryRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  add(laundry: Laundry): Observable<ResponsePayload<any>> {
    return this.httpClient.post(
      '/admin/laundries',
      new LaundryEdit().mapToAPI(laundry)
    );
  }

  edit(id: number, laundry: Laundry): Observable<ResponsePayload<any>> {
    return this.httpClient.put(
      `/admin/laundries/${id}`,
      new LaundryEdit().mapToAPI(laundry)
    );
  }

  getLaundryTypeList(): Observable<LaundryType[]> {
    return this.httpClient
      .get('/admin/laundry-types')
      .pipe(
        map((payload: ResponsePayload<LaundryType[]>) =>
          existOrEmptyArray(
            (payload?.data).map((laundryType) =>
              new LaundryType().mapFromAPI(laundryType)
            )
          )
        )
      );
  }

  delete(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/laundries/${id}`);
  }
}
