<app-dialog
  [dialogTitle]="'screen_name.crop_image' | trans"
  actionAlign="center"
>
  <div dialog-body>
    <div class="image-cropping-container">
      <div class="w-1/2">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="cropperRatio"
          alignImage="center"
          [format]="imageExt"
          (imageCropped)="onImageCropped($event)"
          (loadImageFailed)="onImageFailed()"
          (cropperReady)="onCropperReady()"
        ></image-cropper>
      </div>
      <div class="w-1/2 flex pl-4">
        <img
          *ngIf="isCropperReady"
          [src]="imageData"
          alt="cropped-image"
          class="source-image mx-auto"
        />
      </div>
      <div *ngIf="!isCropperReady" class="image-cropping-placeholder mb-4">
        <div class="w-1/2 bg-gray-400"></div>
        <div class="w-1/2 bg-gray-400 ml-4"></div>
      </div>
    </div>
  </div>
  <div dialog-action>
    <button mat-flat-button (click)="onFinishCropImage()" color="primary">
      {{ "actions.crop_image" | trans }}
    </button>
  </div>
</app-dialog>
