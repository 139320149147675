import { Mapper } from '#interfaces/mapper.interface';
import { existOrEmptyArray, toSnakeCase } from '#utils/helpers';

export class PaginateData<T extends Mapper> implements Mapper {
  data: any[];
  pagination: Pagination;

  mapFromAPI(paginateData: any, factory: () => T): PaginateData<T> {
    this.pagination = new Pagination().mapFromAPI(paginateData?.pagination);
    if (factory) {
      this.data = existOrEmptyArray(paginateData?.data).map((item) =>
        factory().mapFromAPI(item)
      );
    }
    return this;
  }
}

export class Pagination implements Mapper {
  total: number;
  perPage: number;
  page: number;
  lastPage: number;

  mapFromAPI(pagination: any): Pagination {
    this.total = pagination?.total;
    this.perPage = pagination?.per_page;
    this.page = pagination?.page - 1;
    this.lastPage = pagination?.last_page;
    return this;
  }
}

export class PaginateOptionEdit implements Mapper {
  'page'?: number;
  'limit'?: number;

  mapToAPI(paginateOption: PaginateOption): PaginateOptionEdit {
    this.page = paginateOption?.page;
    this.limit = paginateOption?.perPage;
    return this;
  }

  setSearch(key: string, value: PaginateSearchValue): void {
    this[`search[${key}]`] = value;
  }

  setMatch(key: string, value: PaginateSearchValue): void {
    this[`match[${key}]`] = value;
  }

  setSort(key: string, dir: PaginateDirection): void {
    this[`sort[${key}]`] = dir;
  }

  setFilterDate(key: string, dir: PaginateSearchValue): void {
    this[`${key}`] = dir;
  }
}

export interface PaginateOption {
  page: number;
  perPage?: number;
  match?: { [key: string]: PaginateSearchValue };
  search?: { [key: string]: PaginateSearchValue };
  sort?: DatatableSortTarget;
}

export class SortPagination extends PaginateOptionEdit {
  mapToAPI(paginate: PaginateOption): SortPagination {
    super.mapToAPI(paginate);
    const sort = paginate?.sort;
    this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    return this;
  }
}

export interface PaginateSearchBody {
  match?: { [key: string]: PaginateSearchValue };
  search?: { [key: string]: PaginateSearchValue };
}

export interface DatatablePageInfo {
  offset?: number;
  limit?: number;
}

export interface DatatableSortInfo {
  sorts: DatatableSortTarget[];
}

export interface DatatableSortTarget {
  prop: string;
  dir: PaginateDirection;
}

export type PaginateDirection = 'asc' | 'desc';
export type PaginateSearchValue = string | number | boolean;
