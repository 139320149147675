import { Model } from './model';
import { ServiceCategory } from './service-category.model';
import { Mapper } from '#interfaces/mapper.interface';
import { PaginateOption, PaginateOptionEdit } from './pagination.model';
import { ALL_OPTION, DEFAULT_LANGUAGE } from '#utils/const';
import {
  existOrEmptyArray,
  resolveResourcePath,
  toSnakeCase,
} from '#utils/helpers';
import {
  ServiceItemEditTranslation,
  ServiceItemTranslation,
} from './translation.model';

export class ServiceItem extends Model implements Mapper {
  name: string;
  price: number;
  serviceCategory: ServiceCategory;
  serviceCategoryId?: number;
  serviceCategoryName: string;
  image: string | File;
  pivot: OrderServiceItem;
  subTotal: number;
  isActive?: boolean;
  translations: { [key: string]: ServiceItemTranslation };

  mapFromAPI(item: any): ServiceItem {
    this.id = item?.id;
    this.createdAt = item?.created_at;
    this.updatedAt = item?.updated_at;
    this.name = item?.name;
    this.price = item?.price;
    this.serviceCategory = new ServiceCategory().mapFromAPI(
      item?.service_category
    );
    this.serviceCategoryId = this.serviceCategory?.id;
    this.serviceCategoryName = this.serviceCategory?.name;
    this.image = resolveResourcePath(item?.image);
    this.pivot = new OrderServiceItem().mapFromAPI(item?.pivot);
    this.subTotal = this.pivot?.quantity * this.price;
    this.isActive = !!item?.is_active;
    this.translations = {
      [DEFAULT_LANGUAGE]: {
        name: item?.name,
      },
    };
    existOrEmptyArray(item?.name_translations).forEach((name) => {
      this.translations[name?.language_code] = {
        name: name?.content,
      };
    });

    return this;
  }
}

export class OrderServiceItem implements Mapper {
  quantity: number;

  mapFromAPI(itemPivot: any): OrderServiceItem {
    this.quantity = itemPivot?.quantity;

    return this;
  }
}

export class ServiceItemEdit extends Model implements Mapper {
  'name'?: string;
  'image'?: File;
  'is_active'?: number;
  'service_category_id'?: number;
  'price'?: number;
  'translations'?: ServiceItemEditTranslation[];

  mapToAPI(serviceItem: ServiceItem): ServiceItemEdit {
    if (serviceItem?.image instanceof File) {
      this.image = serviceItem?.image;
    }
    this.name = serviceItem?.name;
    this.is_active = serviceItem?.isActive ? 1 : 0;
    this.service_category_id = serviceItem?.serviceCategoryId;
    this.price = serviceItem?.price;
    const translations = { ...serviceItem?.translations } || {};
    const defaultTranslation = translations[DEFAULT_LANGUAGE];
    this.name = defaultTranslation?.name;
    delete translations[DEFAULT_LANGUAGE];

    this.translations = Object.keys(translations).map((key) =>
      new ServiceItemEditTranslation(key).mapToAPI({
        name: translations[key]?.name,
      })
    );
    return this;
  }
}

export class ServiceItemPaginateOptionEdit extends PaginateOptionEdit {
  mapToAPI(
    paginateOption: ServiceItemPaginateOption
  ): ServiceItemPaginateOptionEdit {
    super.mapToAPI(paginateOption);
    const search = paginateOption?.search;
    const match = paginateOption?.match;
    const sort = paginateOption?.sort;
    if (search?.name) {
      this.setSearch('name', search?.name);
    }
    if (+match?.serviceCategoryId !== ALL_OPTION) {
      this.setMatch('service_category_id', match?.serviceCategoryId);
    }
    if (sort?.prop) {
      this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    }

    return this;
  }
}

export interface ServiceItemPaginateOption extends PaginateOption {
  search?: {
    name?: string;
  };
  match?: {
    serviceCategoryId?: number;
  };
}
