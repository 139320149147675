<form [formGroup]="formGroup">
  <div>
    <div class="text-right mat-small">
      {{ getInputLength(formGroup?.controls?.currentPassword) }}/{{
        maxPasswordCount
      }}
    </div>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>
        {{ "change_password.current_password" | trans }}
      </mat-label>
      <input
        formControlName="currentPassword"
        matInput
        required
        [type]="handleInputPassword('onDisplayCurrentPassword', true)"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="handleToggleShowPassword('onDisplayCurrentPassword', $event)"
      >
        <mat-icon>{{
          handleInputPassword("onDisplayCurrentPassword", false)
        }}</mat-icon>
      </button>

      <mat-error>
        <app-validation-error
          [data]="maxPasswordCount"
          [control]="formGroup?.controls?.currentPassword"
        >
        </app-validation-error>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <div class="text-right mat-small">
      {{ getInputLength(formGroup?.controls?.newPassword) }}/{{
        maxPasswordCount
      }}
    </div>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>
        {{ "change_password.new_password" | trans }}
      </mat-label>
      <input
        formControlName="newPassword"
        [type]="handleInputPassword('onDisplayNewPassword', true)"
        matInput
        required
      />
      <button
        mat-icon-button
        matSuffix
        (click)="handleToggleShowPassword('onDisplayNewPassword', $event)"
      >
        <mat-icon>{{
          handleInputPassword("onDisplayNewPassword", false)
        }}</mat-icon>
      </button>

      <mat-error>
        <app-validation-error
          [data]="maxPasswordCount"
          [control]="formGroup?.controls?.newPassword"
        >
        </app-validation-error>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <div class="text-right mat-small">
      {{ getInputLength(formGroup?.controls?.newPasswordConfirm) }}/{{
        maxPasswordCount
      }}
    </div>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>
        {{ "change_password.new_password_confirm" | trans }}
      </mat-label>
      <input
        formControlName="newPasswordConfirm"
        [type]="handleInputPassword('onDisplayNewPasswordConfirm', true)"
        matInput
        required
      />
      <button
        mat-icon-button
        matSuffix
        (click)="
          handleToggleShowPassword('onDisplayNewPasswordConfirm', $event)
        "
      >
        <mat-icon>{{
          handleInputPassword("onDisplayNewPasswordConfirm", false)
        }}</mat-icon>
      </button>
      <mat-error>
        <app-validation-error
          [data]="maxPasswordCount"
          [control]="formGroup?.controls?.newPasswordConfirm"
        >
        </app-validation-error>
      </mat-error>
    </mat-form-field>
  </div>
</form>
