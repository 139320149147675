import { Model } from './model';
import { Mapper } from '#interfaces/mapper.interface';
import { DATA_LANGUAGES, PRINTER_PORTS } from '#utils/const';
import { isNullOrUndefined } from '#utils/helpers';

export class Setting extends Model implements Mapper {
  clearDataAt: string;
  defaultLanguageCode: string[];
  isClearDataManually: boolean;
  phoneReceptionist: string;
  printerIp: string;
  tax: number;
  hotelName: string;
  isShowItemPrice: boolean;
  isEnableLaundry: boolean;
  isEnablePhoneExtension: boolean;
  isEnableFacility: boolean;
  features?: Feature;
  printerPort: string;
  isEnableGuestRequest: boolean;
  isEnableSurvey: boolean;
  extensionPhoneCode: string;
  extensionPhoneServerIp: string;
  extensionPhoneSipPort: string;

  mapFromAPI(setting: any): Setting {
    this.tax = setting?.tax;
    this.isEnableLaundry = !isNullOrUndefined(setting.is_enable_laundry)
      ? !!+setting?.is_enable_laundry
      : true;
    this.isEnableSurvey = !isNullOrUndefined(setting.is_enable_survey)
      ? !!+setting?.is_enable_survey
      : true;
    this.isEnableGuestRequest = !isNullOrUndefined(
      setting.is_enable_guest_request
    )
      ? !!+setting?.is_enable_guest_request
      : true;
    this.isEnablePhoneExtension = !isNullOrUndefined(
      setting.is_enable_phone_extension
    )
      ? !!+setting?.is_enable_phone_extension
      : true;
    this.isEnableFacility = !isNullOrUndefined(setting.is_enable_facility)
      ? !!+setting?.is_enable_facility
      : true;
    this.hotelName = setting?.hotel_name;
    this.isShowItemPrice = !isNullOrUndefined(setting.is_show_item_price)
      ? !!+setting?.is_show_item_price
      : true;
    this.printerIp = setting?.printer_ip;
    this.clearDataAt = isNullOrUndefined(setting?.clear_data_at)
      ? null
      : setting.clear_data_at;
    this.defaultLanguageCode = !setting?.default_language_code
      ? DATA_LANGUAGES
      : JSON.parse(setting?.default_language_code);
    this.isClearDataManually = !isNullOrUndefined(setting.clear_data_at);
    this.phoneReceptionist = setting?.phone_receptionist;
    this.printerPort = setting?.printer_port;
    this.extensionPhoneCode = setting?.extension_phone_code;
    this.extensionPhoneServerIp = setting?.extension_phone_server_ip;
    this.extensionPhoneSipPort = setting?.extension_phone_sip_port;
    return this;
  }
}

export class SettingEdit extends Model implements Mapper {
  'clear_data_at': string;
  'tax': number;
  'is_enable_facility': number;
  'is_enable_phone_extension': number;
  'is_enable_laundry': number;
  'hotel_name': string;
  'default_language_code': string;
  'printer_port': string;
  'printer_ip': string;
  'phone_receptionist': string;
  'is_show_item_price': boolean;
  'is_enable_guest_request': number;
  'is_enable_survey': number;
  'extension_phone_code': string;
  'extension_phone_server_ip': string;
  'extension_phone_sip_port': string;
  mapToAPI(setting: Setting): SettingEdit {
    this.hotel_name = setting?.hotelName;
    this.tax = setting?.tax;
    this.is_enable_laundry = setting?.features?.isEnableLaundry ? 1 : 0;
    this.is_enable_guest_request = setting?.features?.isEnableGuestRequest
      ? 1
      : 0;
    this.is_enable_survey = setting?.features?.isEnableSurvey ? 1 : 0;
    this.is_enable_phone_extension = setting?.features?.isEnablePhoneExtension
      ? 1
      : 0;
    this.is_enable_facility = setting?.features?.isEnableFacility ? 1 : 0;
    this.printer_ip = setting?.printerIp;
    this.is_show_item_price = setting?.isShowItemPrice;
    this.clear_data_at = setting?.isClearDataManually
      ? setting?.clearDataAt
      : null;
    this.default_language_code = JSON.stringify(setting?.defaultLanguageCode);
    this.phone_receptionist = setting?.phoneReceptionist;
    this.printer_port = PRINTER_PORTS[1];
    this.extension_phone_code = setting?.extensionPhoneCode;
    this.extension_phone_server_ip = setting?.extensionPhoneServerIp;
    this.extension_phone_sip_port = setting?.extensionPhoneSipPort;
    return this;
  }
}

export class SettingExtensionPhoneEdit extends Model implements Mapper {
  'extension_phone_code': string;
  'extension_phone_server_ip': string;
  'extension_phone_sip_port': string;

  mapToAPI(setting: Partial<Setting>): SettingExtensionPhoneEdit {
    this.extension_phone_code = setting?.extensionPhoneCode;
    this.extension_phone_server_ip = setting?.extensionPhoneServerIp;
    this.extension_phone_sip_port = setting?.extensionPhoneSipPort;
    return this;
  }
}

export interface Feature {
  isEnableLaundry: boolean;
  isEnablePhoneExtension: boolean;
  isEnableFacility: boolean;
  isEnableGuestRequest: boolean;
  isEnableSurvey: boolean;
}
