import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { User } from '#models/user.model';
import { PasswordEdit } from '#interfaces/auth.interface';

@Injectable()
export class UserRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  login(authInfo: any) {
    return this.httpClient.post('/login', authInfo).pipe(
      map((res: any) => {
        // res.user = this.parseResponse(res.user);
        return res;
      })
    );
  }

  socialLogin(authInfo: any) {
    return this.httpClient.post('/social-login', authInfo);
  }

  logout() {
    return this.httpClient.post('/logout');
  }

  userInfo() {
    return this.httpClient.get('/me').pipe(
      map((res: any) => {
        return this.parseResponse(res.data);
      })
    );
  }

  changePassword(data: PasswordEdit) {
    const dataEdit: {
      new_password: string;
      password: string;
      new_password_confirmation: string;
    } = {
      new_password: data?.newPassword,
      password: data?.currentPassword,
      new_password_confirmation: data?.newPasswordConfirm,
    };
    return this.httpClient.post('/change-password', dataEdit);
  }

  checkEmailExist(email: string) {
    return this.httpClient.get('/user/check-email', { email });
  }

  forgotPassword(resetInfo: any) {
    return this.httpClient.post('/forgot-password', resetInfo);
  }

  resetPassword(resetInfo: any) {
    return this.httpClient.post('/reset-password', resetInfo);
  }

  parseResponse(data: any): User {
    const user = new User();
    user.fill(data);
    return user;
  }
}
