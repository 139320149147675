import { Model } from './model';
import { Mapper } from '#interfaces/mapper.interface';

export class TranslationModel extends Model implements Mapper {
  type?: string;
  ownId?: string;
  content: any;
  languageCode: string;

  mapFromAPI(translationModel: any): TranslationModel {
    this.id = translationModel?.id;
    this.type = translationModel?.type;
    this.ownId = translationModel?.own_id;
    this.content = translationModel?.content;
    this.languageCode = translationModel?.language_code;
    return this;
  }
}

export class FacilityTranslationsEdit extends Model implements Mapper {
  'language_code': string;
  'name': string;
  mapToAPI(data: {
    languageCode: string;
    content: string;
  }): FacilityTranslationsEdit {
    this.language_code = data?.languageCode;
    this.name = data?.content;
    return this;
  }
}

export class SurveyEditTranslation extends Model implements Mapper {
  'language_code': string;
  'content'?: string;
  mapToAPI(data: { languageCode: string; content: string}): SurveyEditTranslation {
    this.language_code = data?.languageCode;
    this.content = data?.content;
    return this;
  }
}

export class InformationEditTranslation implements Mapper {
  'language_code': string;
  'title'?: string;
  'content'?: string;
  'description'?: string;

  constructor(languageCode: string) {
    this.language_code = languageCode;
  }

  mapToAPI(
    informationTranslation: InformationTranslation
  ): InformationEditTranslation {
    this.title = informationTranslation?.title || '';
    this.content = informationTranslation?.content || '';
    this.description = informationTranslation?.description || '';
    return this;
  }
}

export class ServiceItemEditTranslation implements Mapper {
  'language_code': string;
  'name'?: string;

  constructor(languageCode: string) {
    this.language_code = languageCode;
  }

  mapToAPI(
    serviceItemTranslation: ServiceItemTranslation
  ): ServiceItemEditTranslation {
    this.name = serviceItemTranslation?.name;
    return this;
  }
}

export class BaseCategoryEditTranslation implements Mapper {
  'language_code': string;
  'name'?: string;

  constructor(languageCode: string) {
    this.language_code = languageCode;
  }

  mapToAPI(
    categoryTranslation: BaseCategoryTranslation
  ): BaseCategoryEditTranslation {
    this.name = categoryTranslation?.name;
    return this;
  }
}

export class LaundryEditTranslation implements Mapper {
  'language_code': string;
  'name'?: string;

  constructor(languageCode: string) {
    this.language_code = languageCode;
  }

  mapToAPI(laundryTranslation: LaundryTranslation): LaundryEditTranslation {
    this.name = laundryTranslation?.name;
    return this;
  }
}

export interface InformationTranslation {
  title: string;
  content: string;
  description: string;
}

export interface ServiceItemTranslation {
  name: string;
}

export interface BaseCategoryTranslation {
  name: string;
}

export interface LaundryTranslation {
  name: string;
}

export interface MessageTranslation {
  languageCode: string;
  content: string;
}
export interface FacilityTranslation {
  content: string;
}
