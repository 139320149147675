import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '#services/loader.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requestMap = new Map();

  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestKey = this.pushRequest(req.url);

    return next.handle(req).pipe(
      finalize(() => {
        this.shiftRequest(requestKey);
      })
    );
  }

  private pushRequest(uri: string) {
    const requestKey = CryptoJS.MD5(uri + new Date().getTime()).toString();
    if (this.requestMap.size === 0) {
      this.loaderService.spinnerOn();
    }
    this.requestMap.set(requestKey, uri);
    return requestKey;
  }

  private shiftRequest(key: string) {
    // Prevent requests gap
    setTimeout(() => {
      this.requestMap.delete(key);
      if (this.requestMap.size === 0) {
        this.loaderService.spinnerOff();
      }
    });
  }
}
