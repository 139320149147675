import { DialogComponent } from '#components/core/dialog/dialog.component';
import { ComponentService } from '#services/component.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthService } from '#services/auth.service';
import { MessageService } from '#services/message.service';
@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordDialogComponent
  extends DialogComponent
  implements OnInit {
  @ViewChild('ChangePasswordComponent')
  private passwordSetting: ChangePasswordComponent;
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private authService: AuthService,
    componentService: ComponentService,
    private messageService: MessageService
  ) {
    super(dialogRef, componentService);
  }

  ngOnInit(): void {}

  showButtonSubmit(): boolean {
    return this.passwordSetting?.isInvalid;
  }

  onSubmit() {
    if (this.showButtonSubmit()) {
      return;
    }
    const {
      newPassword,
      currentPassword,
      newPasswordConfirm,
    } = this.passwordSetting?.formGroup?.value;
    this.subscribeOnce(
      this.authService.changePassword({
        newPassword,
        currentPassword,
        newPasswordConfirm,
      }),
      () => {
        this.messageService.showMessage(
          this.transObject('message.edit_success')
        );
        this.authService.endSession();
      }
    );
  }
}
