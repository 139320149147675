import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';

export const notEmpty: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const value = control?.value as string;
  return value && !value.toString().trim()
    ? { notEmpty: 'message.text_empty' }
    : null;
};
