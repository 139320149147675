import { TablePageComponent } from '#components/core/table-page/table-page.component';
import { PaginationRepository } from '#repositories/pagination.repository';
import { ComponentService } from '#services/component.service';
import {
  API_URI,
  PaginableType,
  FACILITY_STATUS,
} from '#utils/const';
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FacilityIndication } from '#models/facility-inducation.model';
import { DialogCreateOrEditComponent } from './dialog-create-or-edit/dialog-create-or-edit.component';
import { FacilityIndicationRepository } from '#repositories/facility-indication.repository';

@Component({
  selector: 'app-facility-indication',
  templateUrl: './facility-indication.component.html',
  styleUrls: ['./facility-indication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityIndicationComponent extends TablePageComponent<
  FacilityIndication
> {
  apiUri = API_URI.facility_indication;
  constructor(
    componentService: ComponentService,
    private facilityIndicationRepository: FacilityIndicationRepository,
    changeDetectorRef: ChangeDetectorRef,
    paginationRepository: PaginationRepository<FacilityIndication>
  ) {
    super(paginationRepository, changeDetectorRef, componentService);
  }

  preInit() {
    this.factory = () => new FacilityIndication();
    this.paginableType = PaginableType.FACILITY_INDICATION;
  }

  getStatus(status: number): string {
   return FACILITY_STATUS[status];
  }

  getWorkingTime(row: FacilityIndication): string {
    return !!(row?.openTime && row?.closeTime)
      ? `${row?.openTime} - ${row?.closeTime}`
      : null;
  }

  onCreateOnEdit(row?: FacilityIndication) {
    const res = this.dialogService.showDialog(DialogCreateOrEditComponent, {
      width: '50%',
      data: row,
    });

    this.subscribeOnce(res.afterClosed(), () => {
      this.loadDatasource();
    });
  }

  async onDelete(id: number) {
    if (
      await this.dialogService.confirm(this.trans('message.confirm_delete'))
    ) {
      this.subscribeOnce(this.facilityIndicationRepository.delete(id), () => {
        this.loadDatasource();
      });
    }
  }
}
