<div class="logo">
  <ng-container *ngIf="!isMinimized">
    <div class="logo-icon">
      <mat-icon>location_city</mat-icon>
    </div>
    <div class="logo-text">
      <span class="logo-main-text">Z-Link</span>
      <span class="logo-sub-text">ホテル管理システム</span>
    </div>
  </ng-container>
  <ng-container *ngIf="isMinimized"
    ><div class="logo-icon">
      <mat-icon>location_city</mat-icon>
    </div></ng-container
  >
</div>
<mat-nav-list class="p-0">
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="bg-transparent"
  >
    <mat-tree-node
      *matTreeNodeDef="let node"
      [ngClass]="{ hidden: !!node?.level && isMinimized }"
    >
      <mat-list-item
        [routerLink]="node?.href"
        routerLinkActive="active"
        [ngClass]="{ 'bg-primary-dark': !!node?.level }"
      >
        <ng-container
          [ngTemplateOutlet]="menuItem"
          [ngTemplateOutletContext]="{
            node: node
          }"
        ></ng-container>
      </mat-list-item>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
    >
      <mat-list-item
        [routerLink]="node?.href"
        routerLinkActive="active"
        (mouseenter)="openMenu(trigger)"
        (mouseleave)="closeMenu(trigger)"
        class="mat-menu-item-right"
      >
        <ng-container
          [ngTemplateOutlet]="menuItem"
          [ngTemplateOutletContext]="{
            node: node
          }"
        ></ng-container>
        <mat-icon class="text-left" matTreeNodeToggle *ngIf="!isMinimized">
          {{
            treeControl?.isExpanded(node)
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"
          }}
        </mat-icon>
        <div [matMenuTriggerFor]="menu" #trigger="matMenuTrigger"></div>
      </mat-list-item>
      <mat-menu
        #menu="matMenu"
        [hasBackdrop]="false"
        class="mat-menu-flat primary-theme"
      >
        <div (mouseenter)="keepOpen()" (mouseleave)="removeOpen(trigger)">
          <a
            *ngFor="let child of node?.children"
            [routerLink]="child?.href"
            routerLinkActive="active"
            mat-menu-item
          >
            {{ child?.label | trans }}
          </a>
        </div>
      </mat-menu>
    </mat-tree-node>
  </mat-tree>
</mat-nav-list>
<ng-template let-node="node" #menuItem>
  <section class="flex items-center flex-1">
    <mat-icon>{{ node?.icon }}</mat-icon>
    <span class="menu-title pl-2">
      {{ node?.label | trans }}
    </span>
  </section>
</ng-template>
