import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { AuthService } from 'src/app/services/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShareModule } from '#components/share/share.module';
import { NgxMaskModule } from 'ngx-mask';
/* Others */
import { EmptyPipe } from './pipes/empty.pipe';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
/* Repositories */
import { UserRepository } from './repositories/user.repository';
import { PaginationRepository } from '#repositories/pagination.repository';
import { RoomRepository } from '#repositories/room.repository';
import { InformationRepository } from '#repositories/information.repository';
import { MessageRepository } from '#repositories/message.repository';
import { InformationCategoryRepository } from '#repositories/information-category.repository';
import { FileRepository } from '#repositories/file.repository';
import { OrderRepository } from '#repositories/order.repository';
import { SurveyQuestionRepository } from '#repositories/survey-questions.repository';
import { ServiceCategoryRepository } from '#repositories/service-category.repository';
import { ServiceItemRepository } from '#repositories/service-item.repository';
import { SettingRepository } from '#repositories/setting.repository';
import { LaundryRepository } from '#repositories/laundry.repository';
import { FacilityIndicationRepository } from '#repositories/facility-indication.repository';
/* Service */
import { AppHttpClient } from './services/app-http-client.service';
import { HttpErrorHandler } from './services/http-error-handler.service';

@NgModule({
  declarations: [AppComponent, EmptyPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ShareModule,
    QuillModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
    UserRepository,
    PaginationRepository,
    RoomRepository,
    InformationRepository,
    MessageRepository,
    SettingRepository,
    SurveyQuestionRepository,
    InformationCategoryRepository,
    FileRepository,
    OrderRepository,
    ServiceCategoryRepository,
    ServiceItemRepository,
    LaundryRepository,
    FacilityIndicationRepository,
    AppHttpClient,
    HttpErrorHandler,
    AuthService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
