import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { InformationEdit, Information } from '#models/information.model';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { convertModelToFormData } from '#utils/helpers';
import { map } from 'rxjs/operators';

@Injectable()
export class InformationRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  getInformation(id: number): Observable<Information> {
    return this.httpClient
      .get(`/admin/informations/${id}`)
      .pipe(
        map((payload: ResponsePayload<Information>) =>
          new Information().mapFromAPI(payload?.data)
        )
      );
  }

  addInformation(information: Information): Observable<ResponsePayload<any>> {
    const formData = convertModelToFormData(
      new InformationEdit().mapToAPI(information)
    );
    return this.httpClient.post('/admin/informations', formData, {}, true);
  }

  editInformation(
    id: number,
    information: Information
  ): Observable<ResponsePayload<any>> {
    const formData = convertModelToFormData(
      new InformationEdit().mapToAPI(information)
    );
    return this.httpClient.put(`/admin/informations/${id}`, formData, true);
  }

  deleteInformation(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/informations/${id}`);
  }
}
