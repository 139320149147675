import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements OnInit {
  content: string;
  closeButton = 'actions.close';
  confirmButton = 'actions.confirm_delete';
  note: string;
  confirmColor: ThemePalette = 'warn';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.content = this.data.content;
    const payload = this.data.payload;
    if (payload) {
      this.note = payload.note || '';
      this.closeButton = payload.back || this.closeButton;
      this.confirmButton = payload.confirm || this.confirmButton;
      this.confirmColor = payload.confirmColor || 'warn';
    }
  }
}
