import { Mapper } from '#interfaces/mapper.interface';

export class PrintOption implements Mapper {
  type: PrintOptionType;
  value: any;

  mapFromAPI(option: any): PrintOption {
    this.type = option?.type;
    this.value = option?.value;
    return this;
  }
}

export enum PrintOptionType {
  TEXT_LANG = 'text_lang',
  TEXT_SIZE = 'text_size',
  TEXT_ALIGN = 'text_align',
  TEXT_FONT = 'text_font',
  TEXT = 'text',
  FEED_LINE = 'feed_line',
  IMAGE = 'image',
  CUT = 'cut',
  BAR_CODE = 'bar_code',
  LAYOUT = 'layout',
}
