import { DialogComponent } from '#components/core/dialog/dialog.component';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { FacilityIndication } from '#models/facility-inducation.model';
import { FacilityIndicationRepository } from '#repositories/facility-indication.repository';
import { ComponentService } from '#services/component.service';
import { MessageService } from '#services/message.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CreateOrEditComponent } from '../create-or-edit/create-or-edit.component';

@Component({
  selector: 'app-dialog-create-or-edit',
  templateUrl: './dialog-create-or-edit.component.html',
  styleUrls: ['./dialog-create-or-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCreateOrEditComponent
  extends DialogComponent
  implements OnInit {
  @ViewChild(CreateOrEditComponent)
  createOrEditComponent: CreateOrEditComponent;
  private editButton = 'actions.update';
  private addButton = 'actions.insert';
  private editTitle = 'facility_indication.update_title';
  private createTitle = 'facility_indication.add_title';
  title = this.createTitle;
  submitButton = this.addButton;
  constructor(
    componentService: ComponentService,
    private messageService: MessageService,
    private facilityIndicationRepository: FacilityIndicationRepository,
    public dialogRef: MatDialogRef<DialogCreateOrEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FacilityIndication
  ) {
    super(dialogRef, componentService);
  }

  ngOnInit(): void {
    if (!!this.data) {
      this.title = this.editTitle;
      this.submitButton = this.editButton;
    }
  }

  isUpdate(): boolean {
    return !!this.data?.id;
  }

  isDisabledButtonSubmit(): boolean {
    return this.createOrEditComponent?.formGroup?.invalid;
  }

  handleSubmit(facility: FacilityIndication): Observable<ResponsePayload<any>> {
    if (this.isUpdate()) {
      const id = this.data?.id;
      return this.facilityIndicationRepository.update(facility, id);
    }
    return this.facilityIndicationRepository.create(facility);
  }

  onSubmit() {
    if (this.isDisabledButtonSubmit()) {
      return;
    }

    const facilityIndicationValue = this.createOrEditComponent?.formValue;
    this.subscribeOnce(this.handleSubmit(facilityIndicationValue), () => {
      this.dialogRef.close(true);
      this.messageService.showMessage(this.trans('message.edit_success'));
    });
  }
}
