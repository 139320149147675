import { getNumberRegexp, isStrEmpty, keepInRange } from '#utils/helpers';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective implements OnInit {
  @Input() negative = true;
  @Input() decimal: number = null;
  @Input() floating: number = null;
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() nullable = false;

  private lastValidValue = '';
  private numberRegexp: RegExp;

  constructor(private el: ElementRef, private controlHolder: NgControl) {}

  ngOnInit(): void {
    this.numberRegexp = getNumberRegexp(
      this.decimal,
      this.floating,
      this.negative
    );
    const initValue = this.element?.value;
    this.lastValidValue = this.numberRegexp.test(initValue) ? initValue : '0';
  }

  @HostListener('keydown', ['$event']) onKeyDown(): void {
    // save the current value(before new data, should be always valid)
    this.lastValidValue = this.element?.value;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: Event): void {
    const character = (event as KeyboardEvent)?.key;
    this.preventInvalidInput(character, event);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    const pasteValue = event?.clipboardData?.getData('text');
    this.preventInvalidInput(pasteValue, event);
  }

  @HostListener('input', ['$event']) onInput(): void {
    if (!this.numberRegexp.test(this.element?.value)) {
      this.element.value = this.lastValidValue;
    }
  }

  @HostListener('blur', ['$event']) onBlur(): void {
    this.controlHolder?.control?.patchValue(
      this.blurFilter(this.element?.value)
    );
  }

  get element(): any {
    return this.el?.nativeElement;
  }

  private preventInvalidInput(input: string, event: Event): void {
    const newValue = this.constructNewValue(input);

    if (!this.numberRegexp.test(newValue)) {
      event?.preventDefault();
    }
  }

  /**
   * Remove redundant number characters
   * E.g: 0 at the start or after the floating point at the end
   */
  private blurFilter(value: string): string {
    if (isStrEmpty(value) && this.nullable) {
      this.lastValidValue = null;
      return null;
    }
    let output = value.replace(
      /^(-?)0*([1-9]?\d*)(\.\d*[1-9])?\.?0*$/,
      (g1: string, g2: string, g3: string, g4: string) => {
        if (g2 && !g3 && !g4) {
          return '';
        }
        let result = g2;
        result += g3 ? g3 : '0';
        result += g4 ? g4 : '';
        return result;
      }
    );
    output = output?.replace(/[^\d.-]/g, '');
    output = keepInRange(
      parseFloat(output || '0'),
      this.min,
      this.max
    )?.toString();
    this.lastValidValue = output;

    return output;
  }

  private constructNewValue(inputValue: string): string {
    const currentValue = this.element?.value?.toString() || '';
    const selectionStart = +this.element?.selectionStart;
    const selectionEnd = +this.element?.selectionEnd;

    return `${currentValue.slice(
      0,
      selectionStart
    )}${inputValue}${currentValue.slice(selectionEnd)}`;
  }
}
