import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuillModule } from 'ngx-quill';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslocoRootModule } from '#components/share/transloco-root.module';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DATE_LOCAL_TIMEPICKER, MOMENT_DATE_FORMAT } from '#utils/const';
/* Material modules */
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPrintModule } from 'ngx-print';
/* Components */
import { MessageComponent } from '#components/core/message/message.component';
import { MainLayoutComponent } from '#components/layout/main-layout/main-layout.component';
import { AuthComponent } from '#components/layout/auth/auth.component';
import { DialogComponent } from '#components/core/dialog/dialog.component';
import { ValidationErrorComponent } from '#components/core/validation-error/validation-error.component';
import { HeaderComponent } from '#components/layout/header/header.component';
import { BaseComponent } from '#components/core/base/base.component';
import { FragmentComponent } from '#components/core/fragment/fragment.component';
import { MainNavComponent } from '#components/layout/main-nav/main-nav.component';
import { TablePageComponent } from '#components/core/table-page/table-page.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PageHeaderComponent } from '#components/layout/page-header/page-header.component';
import { ImageCropperDialogComponent } from '#components/share/image-cropper-dialog/image-cropper-dialog.component';
import { InputImageComponent } from '#components/share/input-image/input-image.component';
import { TreeComponent } from '#components/core/tree/tree.component';
import { CategoryFormComponent } from '#components/share/category-form/category-form.component';
import { CategorySelectComponent } from '#components/share/category-select/category-select.component';
import { CategoryFormDialogComponent } from '#components/share/category-form-dialog/category-form-dialog.component';
import { CategoryTreeComponent } from '#components/share/category-tree/category-tree.component';
import { TranslatePipe } from '#pipes/translate.pipe';
import { MatTabScrollRetainerDirective } from '#directives/mat-tab-scroll-retainer.directive';
import { OnlyNumberDirective } from '#directives/only-number.directive';
import { ChangePasswordDialogComponent } from '#components/change-password-dialog/change-password-dialog.component';
import { ChangePasswordComponent } from '#components/change-password-dialog/change-password/change-password.component';
import { FacilityIndicationComponent } from '#components/facility-indication/facility-indication.component';

const MATERIAL_MODULES = [
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatListModule,
  MatMenuModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatTableModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMomentDateModule,
  MatChipsModule,
  MatToolbarModule,
  MatGridListModule,
  MatBadgeModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatSlideToggleModule,
  NgxMatSelectSearchModule,
];

const COMMON_MODULES = [
  CommonModule,
  FormsModule,
  NgxMaskModule,
  ReactiveFormsModule,
  MomentModule,
  RouterModule,
  LayoutModule,
  NgxDatatableModule,
  ImageCropperModule,
  TranslocoRootModule,
  QuillModule,
  NgxPrintModule,
  ...MATERIAL_MODULES,
];

const COMPONENTS = [
  MessageComponent,
  MainLayoutComponent,
  AuthComponent,
  DialogComponent,
  ValidationErrorComponent,
  HeaderComponent,
  PageHeaderComponent,
  BaseComponent,
  FragmentComponent,
  ConfirmDialogComponent,
  WarningDialogComponent,
  MainNavComponent,
  TablePageComponent,
  EmptyDataComponent,
  ImageCropperDialogComponent,
  InputImageComponent,
  TreeComponent,
  CategoryFormComponent,
  CategorySelectComponent,
  CategoryFormDialogComponent,
  CategoryTreeComponent,
  TranslatePipe,
  MatTabScrollRetainerDirective,
  OnlyNumberDirective,
  ChangePasswordDialogComponent,
  ChangePasswordComponent,
  FacilityIndicationComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...COMMON_MODULES],
  exports: [...COMMON_MODULES, ...COMPONENTS],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: MAT_DATE_LOCAL_TIMEPICKER },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMAT },
  ],
})
export class ShareModule {}
