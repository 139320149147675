import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { BaseComponent } from '#components/core/base/base.component';
import { ComponentService } from '#services/component.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  isHandset$: Observable<boolean>;
  isMinimized = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private componentService: ComponentService
  ) {
    super(componentService);
  }

  ngOnInit(): void {
    // TODO: Check memory leak
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map((result) => result.matches),
      shareReplay()
    );

    this.subscribeUntilDestroy(
      this.componentService.layout.isToggleSideContent$,
      () => {
        this.toggleSidenav();
      }
    );
  }

  private toggleSidenav(): void {
    this.isMinimized = !this.isMinimized;
    // Force window resize event to trigger some elements re-calculate
    if (typeof Event === 'function') {
      setTimeout(() => {
        // modern browsers
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }
}
