import { ResponsePayload } from '#interfaces/response-payload.interface';
import { ServiceItem, ServiceItemEdit } from '#models/service-item.model';
import { AppHttpClient } from '#services/app-http-client.service';
import { convertModelToFormData } from '#utils/helpers';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Repository } from './repository';

@Injectable()
export class ServiceItemRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  getServiceItem(id: number): Observable<ResponsePayload<ServiceItem>> {
    return this.httpClient.get(`/admin/service-items/${id}`);
  }

  addServiceItem(serviceItem: ServiceItem): Observable<ResponsePayload<any>> {
    const formData = convertModelToFormData(
      new ServiceItemEdit().mapToAPI(serviceItem)
    );
    return this.httpClient.post('/admin/service-items', formData, {}, true);
  }

  editServiceItem(
    id: number,
    serviceItem: ServiceItem
  ): Observable<ResponsePayload<any>> {
    const formData = convertModelToFormData(
      new ServiceItemEdit().mapToAPI(serviceItem)
    );
    return this.httpClient.put(`/admin/service-items/${id}`, formData, true);
  }

  deleteServiceItem(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/service-items/${id}`);
  }
}
