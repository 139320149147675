import { FragmentComponent } from '#components/core/fragment/fragment.component';
import { BaseCategory } from '#models/base-category.model';
import { ComponentService } from '#services/component.service';
import { ALL_OPTION, NULL_VALUE } from '#utils/const';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySelectComponent
  extends FragmentComponent
  implements OnInit {
  @Input() categories: BaseCategory[];
  get selectedCategoryId(): number {
    return this.selectedCategoryIdTemp;
  }
  @Input() set selectedCategoryId(value: number) {
    if (this.selectedCategoryIdTemp !== value) {
      this.selectedCategoryIdTemp = value;
      this.control?.patchValue(value);
    }
  }
  @Input() includeAllOption = true;
  @Input() includeNoParentOption = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() label = 'actions.select_category';
  @Input() control: FormControl;
  private selectedCategoryIdTemp = ALL_OPTION;
  allOption = ALL_OPTION;
  nullValue = NULL_VALUE;
  @Output() categorySelectChange = new EventEmitter<number>();

  constructor(componentService: ComponentService) {
    super(componentService);
  }

  ngOnInit(): void {
    if (!this.control) {
      this.control = new FormControl(this.selectedCategoryId);
    }
    if (this.disabled) {
      this.control.disable();
    }
  }

  onSelectCategory(event: MatSelectChange): void {
    this.categorySelectChange.emit(+event.value);
  }
}
