import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { AppHttpClient } from '#services/app-http-client.service';
import { Repository } from './repository';
import {
  ServiceCategory,
  ServiceCategoryEdit,
} from '#models/service-category.model';
import { map } from 'rxjs/operators';
import { existOrEmptyArray } from '#utils/helpers';
import { CategoryRepository } from '#interfaces/category-repository.interface';

@Injectable()
export class ServiceCategoryRepository
  extends Repository
  implements CategoryRepository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  getCategories(): Observable<ServiceCategory[]> {
    return this.httpClient
      .get('/admin/service-categories', {
        'sort[updated_at]': 'desc',
      })
      .pipe(
        map((payload: ResponsePayload<ServiceCategory[]>) =>
          existOrEmptyArray(payload?.data).map((category) =>
            new ServiceCategory().mapFromAPI(category)
          )
        )
      );
  }

  deleteCategory(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/service-categories/${id}`);
  }

  toggleCategoryStatus(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.post(`/admin/service-categories/${id}/status`);
  }

  addCategory(
    serviceCategory: ServiceCategory
  ): Observable<ResponsePayload<any>> {
    return this.httpClient.post(
      '/admin/service-categories',
      new ServiceCategoryEdit().mapToAPI(serviceCategory)
    );
  }

  editCategory(
    id: number,
    serviceCategory: ServiceCategory
  ): Observable<ResponsePayload<any>> {
    return this.httpClient.put(
      `/admin/service-categories/${id}`,
      new ServiceCategoryEdit().mapToAPI(serviceCategory)
    );
  }
}
