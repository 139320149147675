import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ComponentService } from '#services/component.service';
import { FragmentComponent } from '../fragment/fragment.component';
import { DEFAULT_LANGUAGE, MAX_CHAR_COUNT } from '#utils/const';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { isString } from '#utils/helpers';
import { notEmpty } from '#validators/notEmpty.validator';
import { FormGroupModel } from '#interfaces/common.interface';

@Component({
  selector: 'app-form-fragment',
  templateUrl: './form-fragment.component.html',
  styleUrls: ['./form-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFragmentComponent<T>
  extends FragmentComponent
  implements OnInit {
  @Input() data: T;
  @Input() isUpdate = false;
  defaultLanguage = DEFAULT_LANGUAGE;
  languages = this.service.loader.setting?.defaultLanguageCode;
  emptyTextValidator = notEmpty;
  isOnlyDefaultLanguageRequired = false;
  formGroup: FormGroup;
  @Output() formComplete = new EventEmitter<boolean>();

  constructor(
    protected formBuilder: FormBuilder,
    componentService: ComponentService
  ) {
    super(componentService);
  }

  ngOnInit(): void {}

  getInputLength(target: FormControl | string, type?: string): number {
    const control = this.getTranslatableControl(target, type);
    return control?.value?.length || 0;
  }

  getTranslatableControl(
    target: FormControl | string,
    type?: string
  ): FormControl {
    let control = target;
    if (isString(target)) {
      const translation = this.getTranslationGroup(target as string);
      control = translation.get(type) as FormControl;
    }
    control = control as FormControl;

    return control;
  }

  getArrayTranslationControl(
    target: FormControl | string,
    type?: string,
    index?: number
  ): FormControl {
    let control = target;

    if (Number.isInteger(index)) {
      const translations = (this.formGroup?.get(
        'translationsArray'
      ) as FormArray).controls;
      const translationsIndex = (translations[index] as FormControl)?.get(
        'translations'
      );
      const translationLanguage = translationsIndex.get(target as string);
      control = translationLanguage.get(type) as FormControl;
      return control;
    }

    return this.getTranslatableControl(target, type);
  }

  getTranslationGroup(code: string): FormGroup {
    const translations = this.formGroup?.get('translations');
    return translations?.get(code) as FormGroup;
  }

  setManualEmptyError(
    control: FormControl,
    message = 'message.text_empty'
  ): void {
    control.setErrors({
      notEmpty: message,
    });
  }

  protected clearEmptyMessage(
    translations: { [key: string]: { content: string } }[]
  ): { [key: string]: { content: string } }[] {
    Object.keys(translations).forEach((key) => {
      if (!translations[key]?.content || !translations[key]?.content.trim()) {
        delete translations[key];
      }
    });
    return translations;
  }

  protected generateTranslateForm(
    isOnlyDefaultLanguageRequired = false,
    singleFormKey = 'name'
  ): FormGroup {
    this.isOnlyDefaultLanguageRequired = isOnlyDefaultLanguageRequired;
    const translations = this.formBuilder.group({});
    this.languages.forEach((code) => {
      const needRequired = this.isTranslationFieldRequired(code);
      translations.addControl(
        code,
        this.formBuilder.group(
          this.generateTranslateFormModel(code, needRequired, singleFormKey)
        )
      );
    });
    return translations;
  }

  // Override to construct different form model
  protected generateTranslateFormModel(
    // This param can be used to discriminate requirement for each language
    languageCode: string,
    needRequired = true,
    singleFormKey = 'name'
  ): FormGroupModel {
    return {
      [singleFormKey]: this.generateTranslateSimpleTextControlModel(
        needRequired
      ),
    };
  }

  protected generateTranslateSimpleTextControlModel(
    needRequired = true
  ): any[] {
    const textValidatorList = [
      Validators.maxLength(MAX_CHAR_COUNT),
      this.emptyTextValidator,
    ];
    if (needRequired) {
      textValidatorList.push(Validators.required);
    }
    return ['', textValidatorList];
  }

  isTranslationFieldRequired(code: string): boolean {
    return (
      !this.isOnlyDefaultLanguageRequired ||
      (this.isOnlyDefaultLanguageRequired && code === DEFAULT_LANGUAGE)
    );
  }

  get isInvalid(): boolean {
    return this.formGroup?.invalid;
  }

  get getViewTranslations(): string {
    const isOdd = this.languages.length % 2 !== 0;
    return isOdd
      ? 'grid grid-cols-1 gap-1 mt-5'
      : 'grid grid-cols-2 gap-3 mt-5';
  }
}
