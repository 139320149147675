import { DialogComponent } from '#components/core/dialog/dialog.component';
import {
  CategoryDialogInput,
  CategoryFormData,
} from '#interfaces/category-detail.interface';
import { BaseCategory } from '#models/base-category.model';
import { ComponentService } from '#services/component.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryFormComponent } from '../category-form/category-form.component';

@Component({
  selector: 'app-category-form-dialog',
  templateUrl: './category-form-dialog.component.html',
  styleUrls: ['./category-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryFormDialogComponent<T extends BaseCategory>
  extends DialogComponent
  implements OnInit {
  @ViewChild(CategoryFormComponent) categoryForm: CategoryFormComponent<T>;
  @Output() categoryFormSubmit = new EventEmitter<CategoryFormData<T>>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CategoryDialogInput<T>,
    public dialogRef: MatDialogRef<CategoryFormDialogComponent<T>>,
    componentService: ComponentService
  ) {
    super(dialogRef, componentService);
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.categoryForm?.isInvalid) {
      return;
    }

    this.categoryFormSubmit.emit({
      isUpdate: this.isUpdate,
      value: this.categoryForm?.formGroup?.value as T,
      id: this.data?.category?.id,
    });
  }

  get isUpdate(): boolean {
    return !!this.data?.category;
  }
}
