import { Mapper } from '#interfaces/mapper.interface';
import { existOrEmptyArray, toSnakeCase } from '#utils/helpers';
import { Model } from './model';
import { PaginateOptionEdit, PaginateOption } from './pagination.model';
import { Room } from './room.model';
import { SurveyQuestion } from './survey-question.model';

export class SurveyResult extends Model implements Mapper {
  room: Room;
  surveyResultDetail: SurveyResultDetail[];

  mapFromAPI(result: any): SurveyResult {
    this.id = result?.id;
    this.createdAt = result?.created_at;
    this.updatedAt = result?.updated_at;
    this.room = new Room().mapFromAPI(result?.room);
    this.surveyResultDetail = existOrEmptyArray(
      result?.survey_result_detail
    ).map((question) => new SurveyResultDetail().mapFromAPI(question));
    return this;
  }
}

export class SurveyResultDetail extends Model implements Mapper {
  content: string;
  surveyQuestion: SurveyQuestion;
  surveyAnswerIds?: number[];

  mapFromAPI(resultDetail): SurveyResultDetail {
    this.content = resultDetail?.content;
    this.surveyQuestion = new SurveyQuestion().mapFromAPI(
      resultDetail?.survey_question
    );
    this.surveyAnswerIds = existOrEmptyArray(resultDetail?.survey_answer_ids);
    return this;
  }
}

export class SurveyResultPaginateOptionEdit extends PaginateOptionEdit {
  mapToAPI(
    paginateOption: SurveyResultPaginateOption
  ): SurveyResultPaginateOptionEdit {
    super.mapToAPI(paginateOption);
    const sort = paginateOption?.sort;
    this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    return this;
  }
}

export interface SurveyResultPaginateOption extends PaginateOption {
  match?: {};
}
