import {
  FormGroup,
  FormArray,
  FormControl,
  ValidationErrors,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ORDER_STATUS } from './const';

export function isset(value: any): boolean {
  return typeof value !== 'undefined';
}

export function windowOpen(url: string, target: string = '_blank'): void {
  window.open(url, target);
}

// Referenced from https://www.usefuldev.com/post/Angular%20Forms:%20how%20to%20get%20only%20the%20changed%20values
export function collectFormUpdated(
  formItem: FormGroup | FormArray | FormControl,
  updatedData: any,
  name?: string
): void {
  if (formItem instanceof FormControl) {
    if (name && formItem.dirty) {
      updatedData[name] = formItem.value;
    }
  } else {
    for (const formControlName in formItem.controls) {
      if (formItem.controls.hasOwnProperty(formControlName)) {
        const formControl = formItem.controls[formControlName];

        if (formControl instanceof FormControl) {
          collectFormUpdated(formControl, updatedData, formControlName);
        } else if (
          formControl instanceof FormArray &&
          formControl.dirty &&
          formControl.controls.length > 0
        ) {
          updatedData[formControlName] = [];
          collectFormUpdated(formControl, updatedData[formControlName]);
        } else if (formControl instanceof FormGroup && formControl.dirty) {
          // Prevent level-2 partial update
          updatedData[formControlName] = formControl.value;
        }
      }
    }
  }
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function existOrEmptyArray(value: any): any[] {
  if (value && Array.isArray(value)) {
    return value;
  } else {
    return [];
  }
}

export function isEmpty(value: any): boolean {
  return value === null || value === undefined;
}

export function isStrEmpty(value: string): boolean {
  return isEmpty(value) || value.trim() === '';
}

export function isString(value: any): boolean {
  return typeof value === 'string' || value instanceof String;
}

export function readSingleFile(
  event: any,
  onload: (file: any, fileURL: string) => void
): void {
  const fileList: FileList = event.target.files;
  if (fileList?.length === 0) {
    return;
  }

  const file = fileList[0];
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => {
    onload(file, fileReader.result.toString());
  };
}

export function removeUndefined(obj: any): any {
  if (isEmpty(obj)) {
    return {};
  }
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
}

export function resolveResourcePath(path: string): string {
  if (isStrEmpty(path)) {
    return '';
  }
  if (path.match(/^http[s]*:\/\//)) {
    return path;
  } else {
    return `${environment.host}${path}`;
  }
}

export function toSnakeCase(value: string): string {
  return value?.replace(
    /[a-z][A-Z]/g,
    (capture) => `${capture[0]}_${capture[1].toLowerCase()}`
  );
}

export function getImageFileError(errors: ValidationErrors): string {
  if (errors?.invalidSize) {
    return 'message.image_size_error';
  }
  if (errors?.invalidExt) {
    return 'message.image_ext_error';
  }
  return '';
}

export function toMB(byte = 0): number {
  return byte / 1048576;
}

export function getOrderStatusText(status: number): string {
  return Object.keys(ORDER_STATUS).find((key) => ORDER_STATUS[key] === status);
}

export function getOrderStatusColor(status: number): string {
  switch (status) {
    case ORDER_STATUS.pending:
      return 'accent';
    case ORDER_STATUS.accepted:
      return 'primary';
    case ORDER_STATUS.done:
      return 'standard';
    default:
      return 'warn';
  }
}

export function getNumberRegexp(
  decimal: number,
  floating: number,
  allowNegative = true
): RegExp {
  const maxDecimal = decimal === null ? '' : decimal?.toString();
  const maxFloating = floating === null ? '' : floating?.toString();
  const negativeFlag = allowNegative ? '1' : '0';
  const floatingFlag = floating !== 0 ? '1' : '0';
  return new RegExp(
    `^-{0,${negativeFlag}}\\d{0,${maxDecimal}}(\\.\\d{0,${maxFloating}}){0,${floatingFlag}}$`
  );
}

export function spliceArray(
  array: any[],
  index: number,
  inserted = [],
  count = 1
): any[] {
  const newArray = [...array];
  newArray.splice(index, count, ...inserted);
  return newArray;
}

export function keepInRange(value: number, min: number, max: number): number {
  if (min !== null && value < min) {
    return min;
  }
  if (max !== null && value > max) {
    return max;
  }

  return value;
}

export function convertModelToFormData(
  model: any,
  form: FormData = null,
  namespace = ''
): FormData {
  const formData = form || new FormData();

  for (const propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || isEmpty(model[propertyName])) {
      continue;
    }

    const property = model[propertyName];
    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (property instanceof Date) {
      formData.append(formKey, property.toISOString());
    } else if (Array.isArray(property)) {
      property.forEach((element: any, index: number) => {
        const tempFormKey = `${formKey}[${index}]`;
        convertModelToFormData(element, formData, tempFormKey);
      });
    } else if (typeof property === 'object' && !(property instanceof File)) {
      convertModelToFormData(property, formData, formKey);
    } else if (property instanceof File) {
      formData.append(formKey, property);
    } else {
      formData.append(formKey, property.toString());
    }
  }
  return formData;
}
