<mat-form-field appearance="outline">
  <mat-label>
    {{ label | trans }}
  </mat-label>
  <mat-select
    [formControl]="control"
    (selectionChange)="onSelectCategory($event)"
    [required]="required"
  >
    <mat-option *ngIf="includeAllOption" [value]="allOption">
      {{ "common.all" | trans }}
    </mat-option>
    <mat-option *ngIf="includeNoParentOption" [value]="nullValue">
      {{ "common.select_none" | trans }}
    </mat-option>
    <ng-container *ngFor="let mainCategory of categories">
      <mat-option [value]="mainCategory?.id">
        <ng-container
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ category: mainCategory }"
        ></ng-container>
      </mat-option>
      <mat-optgroup *ngIf="!includeNoParentOption" class="label-none">
        <mat-option
          *ngFor="let childCategory of mainCategory?.children"
          [value]="childCategory?.id"
          class="bg-gray-100 mat-small"
        >
          <ng-container
            [ngTemplateOutlet]="template"
            [ngTemplateOutletContext]="{ category: childCategory }"
          ></ng-container>
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
  <mat-error>
    <app-validation-error [control]="control"></app-validation-error>
  </mat-error>
</mat-form-field>

<ng-template #template let-category="category">
  <div class="flex justify-between items-center">
    <span class="flex-1 truncate">{{ category?.name }}</span>
    <span
      *ngIf="!category?.isActive || !category?.isParentActive"
      class="mat-small"
    >
      ({{ "actions.private" | trans }})
    </span>
  </div>
</ng-template>
