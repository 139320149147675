import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Repository } from './repository';
import { AppHttpClient } from '#services/app-http-client.service';
import { Room, RoomAccountEdit, RoomEdit } from '#models/room.model';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { map } from 'rxjs/operators';
import { existOrEmptyArray } from '#utils/helpers';

@Injectable()
export class RoomRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }

  getRooms(): Observable<Room[]> {
    return this.httpClient
      .get('/admin/rooms')
      .pipe(
        map((payload: ResponsePayload<Room[]>) =>
          existOrEmptyArray(payload.data).map((item) =>
            new Room().mapFromAPI(item)
          )
        )
      );
  }

  update(room: Room, id: number): Observable<ResponsePayload<any>> {
    const roomUpdate = new RoomEdit().mapToAPI(room);
    return this.httpClient.put(`/admin/rooms/${id}`, roomUpdate);
  }

  create(room: Room): Observable<ResponsePayload<any>> {
    const roomCreate = new RoomEdit().mapToAPI(room);
    return this.httpClient.post('/admin/rooms', roomCreate);
  }

  handleActive(
    id: number,
    nextValue: boolean
  ): Observable<ResponsePayload<any>> {
    const value = { is_active: nextValue };
    return this.httpClient.post(`/admin/rooms/${id}/active`, value);
  }

  checkOut(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.post(`/admin/rooms/${id}/checkout`);
  }

  delete(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/rooms/${id}`);
  }

  updateRoomAccount(room: Room, id: number): Observable<ResponsePayload<any>> {
    const roomUpdate = new RoomAccountEdit().mapToAPI(room);
    return this.httpClient.put(
      `/admin/rooms/${id}/phone-extensions`,
      roomUpdate
    );
  }

  deleteRoomAccount(room: Room, id: number): Observable<ResponsePayload<any>> {
    room.phoneUsername = '';
    room.phonePassword = '';
    const roomUpdate = new RoomAccountEdit().mapToAPI(room);
    return this.httpClient.put(
      `/admin/rooms/${id}/phone-extensions`,
      roomUpdate
    );
  }
}
