import { Model } from './model';
import {
  InformationEditTranslation,
  InformationTranslation,
} from './translation.model';
import {
  existOrEmptyArray,
  resolveResourcePath,
  toSnakeCase,
} from '#utils/helpers';
import { Mapper } from '#interfaces/mapper.interface';
import { InformationCategory } from './information-category.model';
import { PaginateOptionEdit, PaginateOption } from './pagination.model';
import { ALL_CATEGORIES_OPTION, DEFAULT_LANGUAGE } from '#utils/const';

export class Information extends Model implements Mapper {
  title: string;
  content: string;
  description: string;
  sortOrder: number;
  isPublic?: boolean;
  thumbnail?: string | File;
  image?: string | File;
  informationCategoryId?: number;
  informationCategoryName?: string;
  informationCategory: InformationCategory;
  translations: { [key: string]: InformationTranslation };

  mapFromAPI(information: any): Information {
    this.id = information?.id;
    this.createdAt = information?.created_at;
    this.updatedAt = information?.updated_at;
    this.title = information?.title;
    this.content = information?.content;
    this.description = information?.description;
    this.sortOrder = information?.sort_order;
    this.isPublic = information?.is_public ? true : false;
    this.thumbnail = resolveResourcePath(information?.thumbnail);
    this.image = resolveResourcePath(information?.image);
    const contentTranslations = existOrEmptyArray(
      information?.content_translations
    );
    const descriptionTranslations = existOrEmptyArray(
      information?.description_translations
    );
    this.translations = {
      [DEFAULT_LANGUAGE]: {
        title: information?.title,
        description: information?.description,
        content: information?.content,
      },
    };
    existOrEmptyArray(information?.title_translations).forEach((title, i) => {
      this.translations[title?.language_code] = {
        title: title?.content,
        content: contentTranslations[i]?.content,
        description: descriptionTranslations[i]?.content,
      };
    });
    this.informationCategory = new InformationCategory().mapFromAPI(
      information?.information_category
    );
    this.informationCategoryId = this.informationCategory?.id;
    this.informationCategoryName = this.informationCategory?.name;

    return this;
  }
}

export class InformationEdit extends Model implements Mapper {
  'thumbnail'?: File;
  'image'?: File;
  'title': string;
  'content': string;
  'description': string;
  'sort_order'?: number;
  'is_public'?: number;
  'information_category_id': number;
  'translations'?: InformationEditTranslation[];

  mapToAPI(information: Information): InformationEdit {
    if (information?.thumbnail instanceof File) {
      this.thumbnail = information?.thumbnail;
    }
    if (information?.image instanceof File) {
      this.image = information?.image;
    }
    this.sort_order = information?.sortOrder || 1;
    this.is_public = information?.isPublic ? 1 : 0;
    this.information_category_id = information?.informationCategoryId;
    const translations = { ...information?.translations } || {};
    const defaultTranslation = translations[DEFAULT_LANGUAGE];
    this.title = defaultTranslation?.title;
    this.content = defaultTranslation?.content;
    this.description = defaultTranslation?.description;
    delete translations[DEFAULT_LANGUAGE];

    this.translations = Object.keys(translations).map((key) =>
      new InformationEditTranslation(key).mapToAPI({
        title: translations[key]?.title,
        content: translations[key]?.content,
        description: translations[key]?.description,
      })
    );
    return this;
  }
}

export class InformationPaginateOptionEdit extends PaginateOptionEdit {
  mapToAPI(
    paginateOption: InformationPaginateOption
  ): InformationPaginateOptionEdit {
    super.mapToAPI(paginateOption);
    const match = paginateOption?.match;
    const sort = paginateOption?.sort;
    if (+match?.informationCategoryId !== ALL_CATEGORIES_OPTION) {
      this.setMatch('information_category_id', match?.informationCategoryId);
    }
    this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    return this;
  }
}

export interface InformationPaginateOption extends PaginateOption {
  match?: {
    informationCategoryId?: number;
  };
}
