import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MainLayoutComponent } from '#components/layout/main-layout/main-layout.component';
import { AuthComponent } from '#components/layout/auth/auth.component';
import { SettingResolver } from '#resolvers/setting.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'messages', pathMatch: 'full' },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    resolve: {
      setting: SettingResolver,
    },
    children: [
      {
        path: 'information',
        loadChildren: () =>
          import('./components/information/information.module').then(
            (m) => m.InformationModule
          ),
      },
      {
        path: 'information/categories',
        loadChildren: () =>
          import(
            './components/information-category/information-category.module'
          ).then((m) => m.InformationCategoryModule),
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./components/guest-message/guest-message.module').then(
            (m) => m.GuestMessageModule
          ),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import(
            './components/survey-management/survey-management.module'
          ).then((m) => m.SurveyModule),
      },
      {
        path: 'survey/results',
        loadChildren: () =>
          import('./components/survey-result/survey-result.module').then(
            (m) => m.SurveyResultModule
          ),
      },
      {
        path: 'survey/statistics',
        loadChildren: () =>
          import(
            './components/survey-statistics/survey-statistics.module'
          ).then((m) => m.SurveyStatisticsModule),
      },
      {
        path: 'requests',
        loadChildren: () =>
          import('./components/guest-request/guest-request.module').then(
            (m) => m.GuestRequestModule
          ),
      },
      {
        path: 'rooms',
        loadChildren: () =>
          import('./components/room-management/room-management.module').then(
            (m) => m.RoomManagementModule
          ),
      },
      {
        path: 'room-account',
        loadChildren: () =>
          import('./components/room-account/room-account.module').then(
            (m) => m.RoomAccountModule
          ),
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./components/service-product/service-product.module').then(
            (m) => m.ServiceProductModule
          ),
      },
      {
        path: 'services/categories',
        loadChildren: () =>
          import('./components/service-category/service-category.module').then(
            (m) => m.ServiceCategoryModule
          ),
      },
      {
        path: 'laundry',
        loadChildren: () =>
          import('./components/laundry/laundry.module').then(
            (m) => m.LaundryModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./components/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'facility-indication',
        loadChildren: () =>
          import(
            './components/facility-indication/facility-indication.module'
          ).then((m) => m.FacilityIndicationModule),
      },
    ],
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./components/auth/login/login.module').then(
            (m) => m.LoginModule
          ),
      },
    ],
  },
  {
    path: 'loader',
    loadChildren: () =>
      import('./components/loader/loader.module').then((m) => m.LoaderModule),
  },
  {
    path: '**',
    redirectTo: '',
    loadChildren: () =>
      import('./components/auth/login/login.module').then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
