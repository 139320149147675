import { FragmentComponent } from '#components/core/fragment/fragment.component';
import { ImageCropperResponse } from '#interfaces/image-cropper-data.interface';
import { ComponentService } from '#services/component.service';
import { CROPPER_RATIO_1_1, imageFileSizeFactory } from '#utils/const';
import { isStrEmpty, readSingleFile } from '#utils/helpers';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputImageComponent extends FragmentComponent implements OnInit {
  @ViewChild('imageInput') imageInput: ElementRef;
  @Input() get imageUrl(): string {
    return this.pImageUrl;
  }

  set imageUrl(value: string) {
    this.lastImageUrl = this.pImageUrl;
    this.pImageUrl = value;
  }

  @Input() control: FormControl;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() cropperRatio = CROPPER_RATIO_1_1;

  maxImageSize = imageFileSizeFactory.maxSizeMB;
  imageExt: string;

  get hasImage(): boolean {
    return !isStrEmpty(this.imageUrl);
  }

  get isError(): boolean {
    return this.control?.invalid;
  }

  private lastImageUrl: string;
  private lastImageUrlFile: File;
  private pImageUrl: string;

  constructor(
    componentService: ComponentService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(componentService);
  }

  ngOnInit(): void {
    // // For displaying error as dialog
    // this.control.valueChanges
    //   .pipe(takeUntil(this.reCreateSession()))
    //   .subscribe(() => {
    //     if (this.control?.errors) {
    //       const imageError = getImageFileError(this.control?.errors);
    //       this.subscribeOnce(
    //         this.dialogService
    //           .warning(
    //             this.trans(imageError, {
    //               sizeMB: imageFileSizeFactory.maxSizeMB,
    //             })
    //           )
    //           .afterClosed(),
    //         () => {
    //           this.resetFormControl();
    //         }
    //       );
    //     }
    //   });
  }

  onCancel(e: Event) {
    e.preventDefault();
    this.resetFormControl();
  }

  private resetFormControl(): void {
    if (this.imageInput) {
      this.imageInput.nativeElement.value = '';
    }
    this.imageUrl = this.lastImageUrl;
    this.control.reset(this.lastImageUrlFile);
  }

  onChanged(event: Event): void {
    readSingleFile(event, (file, fileURL) => {
      this.control.setValue(file);
      if (!this.isError) {
        this.control.markAsDirty();
        this.imageUrl = fileURL;
        const fileType = file?.type;
        this.imageExt = fileType?.substr(fileType?.lastIndexOf('/') + 1);
        this.onOpenCropDialog(
          event,
          (res: ImageCropperResponse) => {
            this.control.setValue(res?.data?.file);
            this.lastImageUrlFile = res?.data?.file;
            this.imageUrl = res?.data?.image;
          },
          () => {
            this.resetFormControl();
          },
          () => {
            this.imageInput.nativeElement.value = '';
            this.changeDetectorRef.markForCheck();
          },
          this.cropperRatio
        );
      } else {
        this.imageUrl = '';
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  private onOpenCropDialog(
    fileEvent: Event,
    success: (res: any) => void,
    failed: (res: any) => void,
    final: () => void,
    cropperRatio?: number
  ): void {
    this.dialogService
      .showDialog(ImageCropperDialogComponent, {
        width: '60%',
        maxHeight: '70%',
        data: { event: fileEvent, cropperRatio, imageExt: this.imageExt },
      })
      .afterClosed()
      .pipe(
        take(1),
        finalize(() => {
          final();
        })
      )
      .subscribe((res: ImageCropperResponse) => {
        if (res?.success) {
          success(res);
        } else {
          failed(res);
        }
      });
  }
}
