import { FormFragmentComponent } from '#components/core/form-fragment/form-fragment.component';
import { BaseCategory } from '#models/base-category.model';
import { ComponentService } from '#services/component.service';
import { MAX_CHAR_COUNT, NULL_VALUE } from '#utils/const';
import { existOrEmptyArray } from '#utils/helpers';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryFormComponent<T extends BaseCategory>
  extends FormFragmentComponent<T>
  implements OnInit {
  @Input() mainCategories: T[];
  @Input() data: T;
  maxNameCount = MAX_CHAR_COUNT;
  nullValue = NULL_VALUE;

  constructor(
    protected formBuilder: FormBuilder,
    componentService: ComponentService
  ) {
    super(formBuilder, componentService);
  }

  ngOnInit(): void {
    this.doInit();
    this.postInit();
  }

  doInit(): void {
    this.formGroup = this.formBuilder.group({
      parentId: [NULL_VALUE],
      isActive: [true, [Validators.required]],
      translations: this.generateTranslateForm(),
    });
    this.formGroup.patchValue({ ...this.data });
    this.mainCategories = this.mainCategories.filter(
      (category) => category?.id !== this.data?.id
    );
  }

  postInit(): void {}

  get parentDisabled(): boolean {
    return !!existOrEmptyArray(this.data?.children).length;
  }
}
