import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private messageDisplayStatus = new Subject<any>();
  private popupMessageDisplayStatus = new Subject<any>();
  public message: string;

  showMessage$ = this.messageDisplayStatus.asObservable();
  showPopupMessage$ = this.popupMessageDisplayStatus.asObservable();

  constructor(
    private snackBar: MatSnackBar
  ) {}

  flash(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  showMessage(message: string) {
    // this.message = this.translateService.translate(message);
    // this.messageDisplayStatus.next(new Date());
    this.flash(message);
  }

  hideMessage() {
    // this.messageDisplayStatus.next(false);
  }

  showPopupMessage(message: string) {
    this.message = message;
    this.popupMessageDisplayStatus.next(new Date());
  }

  hidePopupMessage() {
    this.popupMessageDisplayStatus.next(false);
  }
}
