<app-dialog [dialogTitle]=" 'screen_name.change_password' | trans" actionAlign="center">
  <div dialog-body>
    <app-change-password
      #ChangePasswordComponent
    ></app-change-password>
  </div>
  <div dialog-action>
    <button
      mat-flat-button
      (click)="onSubmit()"
      [disabled]="showButtonSubmit()"
      color="primary"
    >
      {{ 'actions.update' | trans }}
    </button>
  </div>
</app-dialog>
