<app-empty-data
  [dataSource]="categories"
  (refresh)="fetchCategories()"
  [key]="apiUri"
  [template]="template"
  [displayEmptyMessage]="true"
>
</app-empty-data>

<ng-template #template>
  <div class="w-full xl:w-3/4 mx-auto">
    <mat-card>
      <mat-card-content>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <div class="category-tree-node">
              <button mat-icon-button disabled></button>
              <ng-container
                [ngTemplateOutlet]="treeNodeContent"
                [ngTemplateOutletContext]="{ node: node }"
              ></ng-container>
            </div>
          </mat-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
          >
            <div class="category-tree-node">
              <button
                mat-icon-button
                matTreeNodeToggle
                (click)="markExpanded(node)"
              >
                <mat-icon>
                  {{
                    treeControl?.isExpanded(node)
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"
                  }}
                </mat-icon>
              </button>
              <ng-container
                [ngTemplateOutlet]="treeNodeContent"
                [ngTemplateOutletContext]="{ node: node }"
              ></ng-container>
            </div>
          </mat-tree-node>
        </mat-tree>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<ng-template #treeNodeContent let-node="node">
  <div class="category-name-flex-box">
    <div
      class="category-name-flex-box pl-2 min-w-0"
      (click)="onManualToggle(node)"
    >
      <span class="flex-1 truncate">
        {{ node?.name }}
      </span>
      <span *ngIf="!node?.isActive || !node?.isParentActive" class="mat-small">
        ({{ "actions.private" | trans }})
      </span>
    </div>
    <section class="flex items-center pl-2">
      <button
        mat-icon-button
        (click)="onOpenFormDialog(node)"
        [matTooltip]="'actions.edit' | trans"
        matTooltipPosition="above"
        color="primary"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="onDelete(node)"
        [matTooltip]="'actions.delete' | trans"
        matTooltipPosition="above"
        color="warn"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-slide-toggle
        [matTooltip]="
          (node?.isActive ? 'actions.private' : 'actions.public') | trans
        "
        matTooltipPosition="above"
        color="primary"
        [checked]="node?.isActive"
        (change)="onToggleStatus(node, $event)"
        [disabled]="!node?.isParentActive"
      >
      </mat-slide-toggle>
    </section>
  </div>
</ng-template>
