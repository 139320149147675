<section class="mb-8 cursor-default">
  <div class="flex">
    <div class="flex-1">
      <h1 class="page-title flex items-center m-0 mr-5 font-bold">
        <span class="no-wrap">{{ title }}</span>
      </h1>
    </div>
    <div class="flex-center">
      <ng-content></ng-content>
    </div>
  </div>
</section>
