import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslocoService, TranslocoEvents } from '@ngneat/transloco';
import { Setting } from '#models/setting.model';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLanguageLoaded = new BehaviorSubject<boolean>(false);
  isLoading: Subject<boolean> = new Subject();
  setting$ = new BehaviorSubject<Setting>(null);

  constructor(translateService: TranslocoService) {
    // TODO: check memory leak when changing language
    translateService.events$.subscribe((e: TranslocoEvents) => {
      if (e.type === 'translationLoadSuccess') {
        this.isLanguageLoaded.next(true);
      }
      if (e.type === 'translationLoadFailure') {
        this.isLanguageLoaded.next(false);
      }
    });

    this.isLoading.next(false);
  }

  spinnerOn() {
    this.isLoading.next(true);
  }

  spinnerOff() {
    this.isLoading.next(false);
  }

  get setting(): Setting {
    return this.setting$.getValue();
  }

  set setting(setting: Setting) {
    this.setting$.next(setting);
  }
}
