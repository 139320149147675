import { Mapper } from '#interfaces/mapper.interface';
import { ALL_OPTION } from '#utils/const';
import { existOrEmptyArray, toSnakeCase } from '#utils/helpers';
import { Model } from './model';
import { PaginateOption, PaginateOptionEdit } from './pagination.model';
import { PrintOption } from './printer.model';
import { Room } from './room.model';
import { ServiceItem } from './service-item.model';

export class Order extends Model implements Mapper {
  room: Room;
  serviceItems: ServiceItem[];
  serviceCategoriesStr: string;
  total: number;
  tax: number;
  totalWithTax: number;
  isDone: number;
  roomId: number;
  printerData: PrintOption[];
  singlePage: PrintOption[];

  mapFromAPI(order: any): Order {
    this.id = order?.id;
    this.createdAt = order?.created_at;
    this.updatedAt = order?.updated_at;
    this.room = new Room().mapFromAPI(order?.room);
    this.serviceItems = existOrEmptyArray(order?.service_items).map((item) =>
      new ServiceItem().mapFromAPI(item)
    );
    const serviceCategories = this.serviceItems.map(
      (item) => item?.serviceCategory
    );
    this.serviceCategoriesStr = serviceCategories
      .map((category) => category?.name)
      .join(', ');

    this.total = order?.total;
    this.tax = order?.tax;
    this.totalWithTax = order?.total_with_tax;
    this.isDone = order?.is_done;
    this.roomId = this.room?.id;
    this.printerData = existOrEmptyArray(order?.printer_data).map((option) =>
      new PrintOption().mapFromAPI(option)
    );
    this.singlePage = existOrEmptyArray(order?.single_page).map((option) =>
      new PrintOption().mapFromAPI(option)
    );

    return this;
  }

  get status(): string {
    return this.isDone
      ? 'guest_request.status_done'
      : 'guest_request.status_open';
  }
}

export class OrderPaginateOptionEdit extends PaginateOptionEdit {
  mapToAPI(paginateOption: OrderPaginateOption): OrderPaginateOptionEdit {
    super.mapToAPI(paginateOption);
    const match = paginateOption?.match;
    const sort = paginateOption?.sort;
    if (+match?.isDone !== ALL_OPTION) {
      this.setMatch('is_done', match?.isDone);
    }
    if (+match?.roomId !== ALL_OPTION) {
      this.setMatch('room_id', match?.roomId);
    }
    if (!!match?.id) {
      this.setMatch('id', +match?.id);
    }
    this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    return this;
  }
}

export interface OrderPaginateOption extends PaginateOption {
  match?: {
    isDone?: number;
    roomId?: number;
    id?: number;
  };
}
