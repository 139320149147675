import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { isNullOrUndefined } from '#utils/helpers';
import { FragmentComponent } from '#components/core/fragment/fragment.component';
import { ComponentService } from '#services/component.service';
import { HttpErrorHandler } from '#services/http-error-handler.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-empty-data',
  templateUrl: './empty-data.component.html',
  styleUrls: ['./empty-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyDataComponent extends FragmentComponent implements OnInit {
  isShowContent = false;
  @Input() template: ElementRef;
  @Input() dataSource: any;
  @Input() key: string;
  @Input() displayEmptyMessage = false;
  @Output() refresh = new EventEmitter<any>();

  isFetchDone$: BehaviorSubject<boolean>;

  isNullOrUndefined = isNullOrUndefined;

  constructor(
    componentService: ComponentService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    super(componentService);
  }

  ngOnInit(): void {
    if (this.key) {
      this.isFetchDone$ = this.httpErrorHandler.getApiFetchMapData(this.key);
    }
  }

  onRefresh() {
    this.httpErrorHandler.setApiFetchMapData(this.key, null);
    this.refresh.emit();
  }

  get isEmpty(): boolean {
    if (!this.displayEmptyMessage) {
      return false;
    }

    if (this.dataSource) {
      const isArray = Array.isArray(this.dataSource);
      if ((isArray && this.dataSource.length > 0) || !isArray) {
        return false;
      }
    }

    return true;
  }
}
