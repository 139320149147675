import { Injectable } from '@angular/core';
import {
  InformationCategoryEdit,
  InformationCategory,
} from '#models/information-category.model';
import { Repository } from './repository';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';
import { map } from 'rxjs/operators';
import { existOrEmptyArray } from '#utils/helpers';
import { CategoryRepository } from '#interfaces/category-repository.interface';

@Injectable()
export class InformationCategoryRepository
  extends Repository
  implements CategoryRepository {
  getCategories(): Observable<InformationCategory[]> {
    return this.httpClient
      .get('/admin/information-categories', {
        'sort[updated_at]': 'desc',
      })
      .pipe(
        map((payload: ResponsePayload<InformationCategory>) =>
          existOrEmptyArray(payload?.data).map((category) =>
            new InformationCategory().mapFromAPI(category)
          )
        )
      );
  }

  getCategory(id: number): Observable<ResponsePayload<InformationCategory>> {
    return this.httpClient.get(`/admin/information-categories/${id}`);
  }

  addCategory(category: InformationCategory): Observable<ResponsePayload<any>> {
    return this.httpClient.post(
      '/admin/information-categories',
      new InformationCategoryEdit().mapToAPI(category)
    );
  }

  editCategory(
    id: number,
    category: InformationCategory
  ): Observable<ResponsePayload<any>> {
    return this.httpClient.put(
      `/admin/information-categories/${id}`,
      new InformationCategoryEdit().mapToAPI(category)
    );
  }

  deleteCategory(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/information-categories/${id}`);
  }

  toggleCategoryStatus(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.post(`/admin/information-categories/${id}/status`);
  }
}
