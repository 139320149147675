import { Model } from '#models/model';
import { Mapper } from '#interfaces/mapper.interface';
import { PaginateOption, PaginateOptionEdit } from './pagination.model';
import { existOrEmptyArray, toSnakeCase } from '#utils/helpers';
import {
  LaundryEditTranslation,
  LaundryTranslation,
} from './translation.model';
import { ALL_OPTION, DEFAULT_LANGUAGE } from '#utils/const';

export class Laundry extends Model implements Mapper {
  name: string;
  type: LaundryType;
  typeId: number;
  weight: number;
  hostKey: string;
  itemKey: string;
  isUsing: boolean;
  translations: { [key: string]: LaundryTranslation };

  mapFromAPI(laundry: any): Laundry {
    this.id = laundry?.id;
    this.createdAt = laundry?.created_at;
    this.updatedAt = laundry?.updated_at;
    this.name = laundry?.name;
    this.type = new LaundryType().mapFromAPI(laundry?.type);
    this.typeId = this.type?.id;
    this.weight = laundry?.weight;
    this.hostKey = laundry?.host_key;
    this.itemKey = laundry?.item_key;
    this.isUsing = laundry?.is_using;
    this.translations = {
      [DEFAULT_LANGUAGE]: {
        name: laundry?.name,
      },
    };
    existOrEmptyArray(laundry?.name_translations).forEach((name) => {
      this.translations[name?.language_code] = {
        name: name?.content,
      };
    });
    return this;
  }
}

export class LaundryEdit implements Mapper {
  'name': string;
  'type_id': number;
  'weight': number;
  'host_key': string;
  'item_key': string;
  'translations': LaundryEditTranslation[];

  mapToAPI(laundry: Laundry): LaundryEdit {
    this.type_id = laundry?.typeId;
    this.weight = laundry?.weight;
    this.host_key = laundry?.hostKey;
    this.item_key = laundry?.itemKey;
    const translations = { ...laundry?.translations } || {};
    const defaultTranslation = translations[DEFAULT_LANGUAGE];
    this.name = defaultTranslation?.name;
    delete translations[DEFAULT_LANGUAGE];

    this.translations = Object.keys(translations).map((key) =>
      new LaundryEditTranslation(key).mapToAPI({
        name: translations[key]?.name,
      })
    );

    return this;
  }
}

export class LaundryPaginateOptionEdit extends PaginateOptionEdit {
  mapToAPI(paginateOption: LaundryPaginateOption): LaundryPaginateOptionEdit {
    super.mapToAPI(paginateOption);
    const match = paginateOption?.match;
    const sort = paginateOption?.sort;
    if (match?.typeId !== ALL_OPTION) {
      this.setMatch('type_id', match?.typeId);
    }
    this.setSort(toSnakeCase(sort?.prop), sort?.dir);
    return this;
  }
}

export interface LaundryPaginateOption extends PaginateOption {
  match?: {
    typeId?: number;
  };
}

export class LaundryType extends Model implements Mapper {
  name: string;

  mapFromAPI(laundryType: any): LaundryType {
    this.id = laundryType?.id;
    this.name = laundryType?.name;
    return this;
  }
}
