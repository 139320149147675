import { Injectable } from '@angular/core';
import { AppHttpClient } from '#services/app-http-client.service';
import { Repository } from './repository';
import {
  FacilityIndication,
  FacilityIndicationEdit,
} from '#models/facility-inducation.model';
import { Observable } from 'rxjs';
import { ResponsePayload } from '#interfaces/response-payload.interface';

@Injectable()
export class FacilityIndicationRepository extends Repository {
  constructor(httpClient: AppHttpClient) {
    super(httpClient);
  }
  create(facility: FacilityIndication): Observable<ResponsePayload<any>> {
    const newFacility = new FacilityIndicationEdit().mapToAPI(facility);
    return this.httpClient.post('/admin/facilities', newFacility);
  }

  update(
    facility: FacilityIndication,
    id: number
  ): Observable<ResponsePayload<any>> {
    const newFacility = new FacilityIndicationEdit().mapToAPI(facility);
    return this.httpClient.put(`/admin/facilities/${id}`, newFacility);
  }

  delete(id: number): Observable<ResponsePayload<any>> {
    return this.httpClient.delete(`/admin/facilities/${id}`);
  }
}
