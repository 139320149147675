import { ValidatorFn, FormGroup, FormControl } from '@angular/forms';

export function isEqualValidator(
  matchKey?: string,
  targetKey?: string
): ValidatorFn {
  return (formGroup: FormGroup): FormGroup => {
    const matchControl = formGroup.get(matchKey) as FormControl;
    const targetControl = formGroup.get(targetKey) as FormControl;
    if (matchControl?.value !== targetControl?.value) {
      targetControl?.setErrors({ isEqual: true });
      return;
    }
    if (targetControl?.errors && targetControl?.getError('isEqual')) {
      delete targetControl?.errors?.isEqual;
    }
    if (!targetControl?.errors) {
      return;
    }
    if (Object.keys(targetControl?.errors).length === 0) {
      targetControl?.setErrors(null);
    }
    return;
  };
}
