import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComponentService } from '#services/component.service';
import { FragmentComponent } from '../fragment/fragment.component';
import { toMB } from '#utils/helpers';
import { REGEX } from '#utils/const';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorComponent
  extends FragmentComponent
  implements OnInit {
  @Input() control: FormControl;
  @Input() data?: any;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    componentService: ComponentService
  ) {
    super(componentService);
  }

  ngOnInit() {
    this.subscribeUntilDestroy(this.control.statusChanges, () => {
      this.changeDetectorRef.markForCheck();
    });
  }

  getMessage() {
    let message = '';
    if (this.control.errors) {
      if (this.control.errors.email) {
        message = 'Oops that doesn’t look like a valid email. Please try again';
      }
      if (this.control.errors.minlength) {
        message = 'This value is too short';
      }
      if (this.control.errors.maxlength) {
        message = this.trans('message.field_max_length_error', {
          count: this.control.errors.maxlength?.requiredLength,
        });
      }
      if (this.control.errors.birthday) {
        message = 'Oops! That date hasn’t happened yet.';
      }
      if (this.control.errors.pattern) {
        message = this.control.errors.pattern.invalidMessage;
      }
      if (this.control.errors.invalidSize?.invalid) {
        message = this.trans('message.image_size_error', {
          sizeMB: toMB(this.control.errors.invalidSize?.maxSize),
        });
      }
      if (this.control.errors.invalidExt?.invalid) {
        message = this.trans('message.image_ext_error');
      }
      if (this.control.errors.isEqual) {
        message = this.trans('message.change_password_error');
      }
      if (this.control.errors.notEmpty) {
        message = this.trans(this.control.errors.notEmpty);
      }
      if (this.control.errors?.min) {
        message = this.trans('message.min_value_invalid', {
          min: +this.control.errors?.min?.min - 1,
        });
      }
      if (this.control.errors.required) {
        message = this.trans('message.field_required_error');
      }
    }
    return message;
  }
}
