import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FragmentComponent } from '../fragment/fragment.component';
import { Expandable } from '#interfaces/expandable.interface';
import { ComponentService } from '#services/component.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeComponent<T extends Expandable<T>>
  extends FragmentComponent
  implements OnInit {
  expandedNodes: T[] = [];
  treeControl = new FlatTreeControl<T>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this.getTreeTransformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(componentsService: ComponentService) {
    super(componentsService);
  }

  ngOnInit(): void {}

  setData(data: T[]): void {
    this.dataSource.data = data;
    this.keepTreeExpanded();
  }

  hasChild(_: number, node: T): boolean {
    return node.expandable;
  }

  onManualToggle(node: T): void {
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapse(node);
    } else {
      this.treeControl.expand(node);
    }
    this.markExpanded(node);
  }

  markExpanded(node: T): void {
    const expanded = this.treeControl.isExpanded(node);
    if (expanded) {
      this.expandedNodes.push(node);
    } else {
      const index = this.expandedNodes.findIndex(
        (eachNode) => eachNode?.id === node?.id
      );
      if (index >= 0) {
        this.expandedNodes.splice(index, 1);
      }
    }
  }

  keepTreeExpanded(): void {
    this.treeControl.dataNodes.forEach((node) => {
      this.expandedNodes.some((expandedNode) => {
        if (expandedNode?.id === node?.id) {
          this.treeControl.expand(node);
          return true;
        }
        return false;
      });
    });
  }

  private getTreeTransformer(node: T, level: number): T {
    return {
      expandable: !!node.children && node.children.length > 0,
      level,
      ...node,
    };
  }
}
