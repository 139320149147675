<form [formGroup]="formGroup" #editForm="ngForm">
  <app-category-select
    [categories]="mainCategories"
    [includeAllOption]="false"
    [includeNoParentOption]="true"
    [control]="formGroup?.controls?.parentId"
    [disabled]="parentDisabled"
    label="common.parent_category_label"
    required
  ></app-category-select>
  <div class="mb-2">
    <ng-container *ngFor="let lang of languages">
      <div class="text-right mat-small">
        {{ getInputLength(lang, "name") }}/{{ maxNameCount }}
      </div>
      <mat-form-field appearance="outline" class="w-full mb-1">
        <mat-label>
          {{ "common.category_name_input" | trans: { postfix: lang } }}
        </mat-label>
        <input
          [required]="isTranslationFieldRequired(lang)"
          matInput
          [formControl]="getTranslatableControl(lang, 'name')"
        />
        <mat-error>
          <app-validation-error
            [control]="getTranslatableControl(lang, 'name')"
            [data]="maxNameCount"
          >
          </app-validation-error>
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>
  <mat-radio-group required formControlName="isActive" color="primary">
    <mat-radio-button [value]="true">
      {{ "actions.public" | trans }}
    </mat-radio-button>
    <mat-radio-button [value]="false" class="ml-4">
      {{ "actions.private" | trans }}
    </mat-radio-button>
  </mat-radio-group>
  <ng-content select="[extra-controls]"></ng-content>
</form>
