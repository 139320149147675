<div>
  <div class="flex justify-between items-center mb-2">
    <span class="text-lg">
      {{ title }}
    </span>
    <button
      *ngIf="isError"
      mat-icon-button
      (click)="onCancel($event)"
      color="warn"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div (click)="imageInput.click()" class="ratio-image hover:shadow-outline">
    <img *ngIf="hasImage" [src]="imageUrl" alt="image" class="object-contain" />
    <div *ngIf="!hasImage" class="empty-image-box">
      <span class="empty-image-label">
        {{ placeholder }}
      </span>
    </div>
    <div class="ratio-image-icon-box">
      <mat-icon class="bg-white rounded">image</mat-icon>
    </div>
  </div>
  <input
    #imageInput
    type="file"
    accept="image/*"
    (change)="onChanged($event)"
    class="hidden"
  />
  <mat-error *ngIf="isError" class="mt-2">
    <app-validation-error [control]="control" [data]="maxImageSize">
    </app-validation-error>
  </mat-error>

  <div class="mt-2 text-note">
    <ng-content select="[note]"></ng-content>
  </div>
</div>
