import { Model } from './model';
import { Mapper } from '#interfaces/mapper.interface';
import {
  FacilityTranslationsEdit,
  FacilityTranslation,
} from './translation.model';
import { existOrEmptyArray, isNullOrUndefined } from '#utils/helpers';
import { DEFAULT_LANGUAGE } from '#utils/const';

export class FacilityIndication extends Model implements Mapper {
  createdAt: string;
  updatedAt?: string;
  status: number;
  isUpdateManually: boolean;
  minSize: number;
  name: string;
  translations: { [key: string]: FacilityTranslation };
  maxSize: number;
  sensorKey: string;
  statusManual: number;
  openTime: string;
  closeTime: string;
  mapFromAPI(data: any): FacilityIndication {
    this.openTime = data?.open_time;
    this.closeTime = data?.close_time;
    this.createdAt = data?.created_at;
    this.name = data?.name;
    this.id = data?.id;
    this.updatedAt = data?.updated_at;
    this.status = data?.status;
    this.isUpdateManually = !isNullOrUndefined(data?.status_manual);
    this.sensorKey = data?.sensor_key;
    this.minSize = data?.min_size;
    this.maxSize = data?.max_size;
    this.statusManual = data?.status_manual;
    this.translations = {
      [DEFAULT_LANGUAGE]: { content: data?.name },
    };
    existOrEmptyArray(data?.name_translations).forEach((translation) => {
      this.translations[translation?.language_code] = {
        content: translation?.content,
      };
    });
    return this;
  }
}

export class FacilityIndicationEdit extends Model implements Mapper {
  'name': string;
  'open_time': string;
  'close_time': string;
  'min_size': number;
  'status_manual': number;
  'translations': FacilityTranslationsEdit[];
  'max_size': number;
  'sensor_key': string;
  mapToAPI(facility: FacilityIndication): FacilityIndicationEdit {
    this.close_time = facility?.closeTime;
    this.open_time = facility?.openTime;
    const newTranslations = { ...facility?.translations };
    this.status_manual = facility?.isUpdateManually
      ? facility?.statusManual
      : null;
    this.name = facility?.translations[DEFAULT_LANGUAGE]?.content;
    delete newTranslations[DEFAULT_LANGUAGE];
    this.sensor_key = !facility?.isUpdateManually ? facility?.sensorKey : null;
    this.min_size = !facility?.isUpdateManually ? facility?.minSize : null;
    this.max_size = !facility?.isUpdateManually ? facility?.maxSize : null;
    this.translations = Object.keys(newTranslations).map((key: string) =>
      new FacilityTranslationsEdit().mapToAPI({
        languageCode: key,
        content: newTranslations[key]?.content,
      })
    );
    return this;
  }
}
