import { Model } from './model';
import { existOrEmptyArray } from '#utils/helpers';
import { Mapper } from '#interfaces/mapper.interface';
import {
  SurveyEditTranslation,
} from '#models/translation.model';
import { SurveyAnswer, SurveyAnswerEdit } from '#models/survey-answer.model';
import { DEFAULT_LANGUAGE } from '#utils/const';

export class SurveyQuestion extends Model implements Mapper {
  translations: { [key: string]: { content: string } };
  surveyAnswers?: SurveyAnswer[];
  sortOrder: number;
  createdAt: string;
  content: string;
  isRequired: boolean;
  updatedAt?: string;
  unAnswersRatio?: number;
  questionType: number;
  mapFromAPI(survey: any): SurveyQuestion {
    this.id = survey?.id;
    this.isRequired = survey?.is_required;
    this.questionType = survey?.question_type;
    this.translations = {
      [DEFAULT_LANGUAGE]: { content: survey?.content },
    };
    existOrEmptyArray(survey?.content_translations).forEach(
      (translation) =>
        (this.translations[translation?.language_code] = {
          content: translation?.content,
        })
    );
    this.surveyAnswers = existOrEmptyArray(
      survey?.survey_answers
    ).map((content) => new SurveyAnswer().mapFromAPI(content));
    this.sortOrder = survey?.sort_order;
    this.content = survey?.content;
    this.createdAt = survey?.created_at;
    this.updatedAt = survey?.updated_at;
    this.unAnswersRatio = survey?.unanswers_ratio;
    return this;
  }
}

export class SurveyQuestionEdit extends Model implements Mapper {
  'translations': SurveyEditTranslation[];
  'is_required': boolean;
  'answers': SurveyAnswerEdit[];
  'content': string;
  'sort_order': number;
  'question_type': number;

  mapToAPI(update: SurveyQuestion): SurveyQuestionEdit {
    this.content = update?.translations[DEFAULT_LANGUAGE]?.content;
    const newContentTranslation = { ...update.translations };
    delete newContentTranslation[DEFAULT_LANGUAGE];
    this.translations = Object.keys(newContentTranslation).map((key) =>
      new SurveyEditTranslation().mapToAPI({ languageCode: key, content: newContentTranslation[key]?.content})
    );
    this.answers = existOrEmptyArray(update?.surveyAnswers).map((item) =>
      new SurveyAnswerEdit().mapToAPI(item)
    );
    this.is_required = update?.isRequired;
    this.sort_order = update?.sortOrder;
    this.question_type = update?.questionType;
    return this;
  }
}
