<div mat-dialog-content>
  <p class="text-center">{{ content }}</p>
  <p *ngIf="note" class="text-center mat-small text-warn">* {{ note }}</p>
</div>
<div mat-dialog-actions class="flex justify-center">
  <button mat-flat-button [mat-dialog-close]="true" [color]="confirmColor">
    {{ confirmButton | trans }}
  </button>
  <button mat-stroked-button [mat-dialog-close]="false">
    {{ closeButton | trans }}
  </button>
</div>
