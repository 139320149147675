import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './storage.service';
import { UserProfileService } from './user-profile.service';
import { UserRepository } from '#repositories/user.repository';
import { AuthInfo, PasswordEdit } from '#interfaces/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  accessToken = '';

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private userRepository: UserRepository,
    private storageService: LocalStorageService,
    private router: Router
  ) {}

  async login(authInfo: AuthInfo) {
    return this.userRepository
      .login(authInfo)
      .toPromise()
      .then(async (res: any) => {
        if (res.data && res.data.token) {
          this.setToken(res.data.token);
          this.loggedIn();
        }
        return res;
      });
  }

  async socialLogin(authInfo: any) {
    return this.userRepository
      .socialLogin(authInfo)
      .toPromise()
      .then(async (res: any) => {
        if (res.data && res.data.token) {
          this.setToken(res.access_token);
        }
        return res;
      });
  }

  endSession(): void {
    this.logout()
      .toPromise()
      .finally(() => {
        this.router.navigate(['/login']);
        this.storageService.unset('access_token');
        this.accessToken = null;
      });
  }

  setToken(token: string) {
    if (token) {
      this.accessToken = token;
      this.storageService.set('access_token', token);
    }
  }

  async verifyToken() {
    return new Promise((resolve, reject) => {
      const token = this.storageService.get('access_token');
      if (token) {
        this.accessToken = token;
        this.loggedIn();
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  loggedIn(): void {
    const redirectUrl = this.redirectUrl
      ? this.router.parseUrl(this.redirectUrl)
      : '/';
    this.router.navigateByUrl(redirectUrl);
  }

  forgotPassword(resetInfo: any): Observable<any> {
    return this.userRepository.forgotPassword(resetInfo);
  }

  resetPassword(resetInfo: any): Observable<any> {
    return this.userRepository.resetPassword(resetInfo);
  }

  changePassword(info: PasswordEdit): Observable<any> {
    return this.userRepository.changePassword(info);
  }

  logout(): Observable<any> {
    return this.userRepository.logout();
  }

  removeToken() {
    this.storageService.unset('access_token');
    this.accessToken = null;
  }

  get isLoggedIn(): boolean {
    const token = this.storageService.get('access_token');
    return !!token;
  }
}
